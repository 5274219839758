import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'
import DeleteModal from 'pages/parts/DeleteModal'
import TableControl from 'pages/parts/TableControl'

import { PGET, DELETE, GET } from 'helpers/api'
import { checkRole, findFromArrayById } from "helpers/helpers"

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/web/api/product-group-pageList/`

	const canCreate = checkRole('POS_PRODUCT_GROUP_ADD')
	const canUpdate = checkRole('POS_PRODUCT_GROUP_EDIT')
	const canDelete = checkRole('POS_PRODUCT_GROUP_DELETE')

	const [pageCount, setPageCount] = useState(0)
	const [modalDelete, setModalDelete] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ posId: 0, productGroupId: 0, index: 0 })
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
	})
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function deleteItem() {
		const response = await DELETE("/services/web/api/product-group/"
			+ deleteModalParams.posId + '/' + deleteModalParams.productGroupId, {}, { loader: true })
		if (response) {
			getData()
			setModalDelete(false)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId, filterData)
		if (response && response.data) {
			setPageCount(Math.ceil((response?.headers['x-total-count']) / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId, { ...filterData, page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper", {}, { loader: true })
		if (response) {
			setPoses(response)
		}

	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getData(true)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-between mb-3">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-outline-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>

						{canCreate &&
							<Link className="btn btn-outline-primary" to="/grouping/create">
								{t('CREATE')}
							</Link>
						}
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableGrouping || reduxSettings.tableGrouping?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableGrouping || reduxSettings.tableGrouping?.pos) &&
											<td>{item.posName}</td>
										}
										<td>{item.productGroupName}</td>
										<td className="text-center">{item.productGroupBarcode}</td>
										<td>
											<div className="table-actions">
												{canUpdate &&
													<Link to={`/grouping/update/${item.posId}/${item.productGroupId}`}>
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												}
												{canDelete &&
													<div className="table-action-button table-action-danger-button"
														onClick={() => {
															setDeleteModalParams({ posId: item.posId, productGroupId: item.productGroupId, index: index });
															setModalDelete(true)
														}}>
														<i className="uil-times"></i>
													</div>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableGrouping'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
