import React, { useEffect, useState } from 'react'

import { PGET } from 'helpers/api'
import { formatDateWithTime } from 'helpers/helpers'

function Welcome() {

	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET('/services/web/api/news-pageList')
		if (response && response.data) {
			setData(response.data)
		}
	}

	useEffect(() => {
		getData()
	}, []);

	return (
		<>
			<div className="row news mt-3">
				{data.map((item, index) => (
					<div className="new-card mb-3" key={index}>
						<div dangerouslySetInnerHTML={{ __html: item.title }} />
						<div dangerouslySetInnerHTML={{ __html: item.description }} />
						<div className="new-date">
							{formatDateWithTime(item.newsDate)}
						</div>
					</div>
				))
				}
			</div>
		</>
	)
}

export default Welcome