import {
	FcShop,
	FcPaid,
	FcCurrencyExchange,
	FcBusiness,
	FcSalesPerformance,
	FcSportsMode,
	FcSettings,
	FcPodiumWithSpeaker,
	FcLineChart,
	FcRedo,
	FcBarChart,
	FcAnswers,
	FcBookmark,
	FcCollapse,
	FcFinePrint,
	FcLeft,
	FcLeave,
	FcAcceptDatabase,
	FcBiotech,
	FcDownload,
	FcCircuit,
	FcCandleSticks,
	FcAbout,
	FcCellPhone,
	FcSafe,
	FcInTransit,
	FcShipped,
	FcElectricity,
	FcHighPriority,
} from 'react-icons/fc'

const pos = [
	{
		icon: <FcShop />,
		title: 'POS',
		show: false,
		children: [
			{
				icon: <FcShop />,
				title: 'POS',
				show: false,
				children: [
					{
						title: true,
						i18n: "POS"
					},
					{
						url: "/documents-in",
						i18n: "GOOD_RECEPTION",
						icon: <FcAnswers />,
						role: "POS_DOCUMENT_IN"
					},
					{
						url: "/nomenclature",
						i18n: "NOMENCLATURE",
						icon: <FcBookmark />,
						role: "POS_NOMENKLATURA"
					},
					{
						url: "/revaluation",
						i18n: "REVALUATION",
						icon: <FcCollapse />,
						role: "POS_REVALUATION"
					},
					{
						url: "/history",
						i18n: "HISTORY",
						icon: <FcFinePrint />,
						role: "POS_HISTORY"
					},
					{
						url: "/documents-out",
						i18n: "RETURN_TO_SIPPLIER",
						icon: <FcLeft />,
						role: "POS_DOCUMENT_OUT"
					},
					{
						url: "/write-off",
						i18n: "WRITE_OFF",
						icon: <FcLeave />,
						role: "POS_WRITE_OFF"
					},
					{
						url: "/inventory",
						i18n: "INVENTORY",
						icon: <FcAcceptDatabase />,
						role: "POS_INVENTORY"
					},
					{
						url: "/scale",
						i18n: "SCALE",
						icon: <FcBiotech />,
						role: "POS_SCALE"
					},
					{
						url: "/price-tags",
						i18n: "PRICE_TAGS",
						icon: <FcCurrencyExchange />,
						role: "POS_PRICE_TAGS"
					},
					{
						url: "/transfer",
						i18n: "DISPLACEMENT",
						icon: <FcInTransit />,
						role: "POS_TRANSFER"
					},
					{
						url: "/pos-transfer",
						i18n: "DISPLACEMENT_WMS",
						icon: <FcShipped />,
						role: "POS_TRANSFER_WMS"
					},
					{
						url: "/orders",
						i18n: "ORDERS",
						icon: <FcDownload />,
						role: "POS_ORDERS"
					},
					{
						url: "/grouping",
						i18n: "GROUPING",
						icon: <FcCircuit />,
						role: "POS_PRODUCT_GROUP"
					},
					{
						url: "/package",
						i18n: "PACKAGES",
						icon: <FcCandleSticks />,
						role: "POS_PRODUCT_BOX"
					},
					{
						url: "/promotion",
						i18n: "PROMOTION",
						icon: <FcPaid />,
						role: "POS_PROMOTION"
					},
					{
						url: "/cheques",
						i18n: "CHEQUES",
						icon: <FcAbout />,
						role: "POS_CHEQUE"
					},
					{
						url: "/deleted-cheques",
						i18n: "DELETED_CHEQUES",
						icon: <FcHighPriority />,
						role: "POS_CHEQUE"
					},
					{
						url: "/notifications",
						i18n: "NOTIFICATIONS",
						icon: <FcCellPhone />,
						role: "POS_NOTIFICATIONS"
					},
					// {
					// 	url: "/reports",
					// 	i18n: "REPORTS",
					// 	icon: <FcRedo />,
					// 	role: "POS_REPORTS"
					// },
				]
			},
			{
				icon: <FcBarChart />,
				title: 'REPORTS',
				show: false,
				children: [
					{
						title: true,
						i18n: "REPORTS"
					},
					{
						url: "/report/balance",
						i18n: "BALANCE_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_BALANCE_PRODUCT"
					},
					{
						url: "/report/incomes",
						i18n: "INCOME_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_DOCUMENT_IN"
					},
					{
						url: "/report/sales",
						i18n: "SALES_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_SALES"
					},
					{
						url: "/report/expenses",
						i18n: "EXPENSE_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_EXPENSE"
					},
					{
						url: "/report/expiration-date",
						i18n: "EXPIRATION_DATE_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_EXPDATE"
					},
					{
						url: "/report/return-products",
						i18n: "RETURN_OF_GOODS",
						icon: <FcRedo />,
						role: "POS_REPORT_RETURNED_ITEMS"
					},
					{
						url: "/report/gross-income",
						i18n: "GROSS_INCOME",
						icon: <FcRedo />,
						role: "POS_REPORT_GROSS_INCOME"
					},
					{
						url: "/report/category-managment",
						i18n: "CATEGORY_MANAGEMENT",
						icon: <FcRedo />,
						role: "POS_REPORT_MML"
					},
					{
						url: "/report/suppliers",
						i18n: "SUPPLIERS",
						icon: <FcRedo />,
						role: "POS_REPORT_ORGANIZATION"
					},
					{
						url: "/report/clients",
						i18n: "CLIENTS",
						icon: <FcRedo />,
						role: "POS_REPORT_CLIENTS"
					},
					{
						url: "/report/summary-kpi",
						i18n: "SUMMARY_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_SUM1"
					},
					{
						url: "/report/summary",
						i18n: "SUMMARY_REPORT_2",
						icon: <FcRedo />,
						role: "POS_REPORT_SUM2"
					},
					// {
					// 	url: "/report/total",
					// 	i18n: "TOTAL_REPORT",
					// 	icon: <FcRedo />,
					// 	role: "POS_REPORTS"
					// },
					{
						url: "/report/write-off",
						i18n: "WRITE_OFF_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_WRITE_OFF"
					},
					{
						url: "/report/cashiers",
						i18n: "CASHIERS_REPORT",
						icon: <FcRedo />,
						role: "POS_REPORT_CASHIER"
					},
				]
			},
			{
				icon: <FcLineChart />,
				title: 'ANALYTICS',
				show: false,
				role: "POS_ANALYTICS",
				children: [
					{
						title: true,
						i18n: "ANALYTICS"
					},
					{
						url: "/dashboard",
						i18n: "HOME",
						icon: <FcElectricity />,
						role: "POS_MAIN"
					},
					{
						url: "/analytics/recommended-orders",
						i18n: "RECOMMENDED_ORDERS",
						icon: <FcElectricity />,
						role: "POS_REPORT_RECOMMENDED"
					},
					{
						url: "/analytics/frozen-funds",
						i18n: "FROZEN_FUNDS",
						icon: <FcElectricity />,
						role: "POS_REPORT_FROZEN"
					},
					{
						url: "/analytics/oos",
						i18n: "OOS",
						icon: <FcElectricity />,
						role: "POS_REPORT_OOS"
					},
					{
						url: "/analytics/abc",
						i18n: "ABC",
						icon: <FcElectricity />,
						role: "POS_REPORT_ABC"
					},
					{
						url: "/analytics/marginality",
						i18n: "MARGINALITY_CATEGORY",
						icon: <FcElectricity />,
						role: "POS_REPORT_MARGINALITY"
					},

					{
						url: "/analytics/product-action",
						i18n: "PRODUCT_ACTION",
						icon: <FcElectricity />,
						role: "POS_REPORT_PRODUCT_ACTION"
					},
					{
						url: "/analytics/product-unsold",
						i18n: "PRODUCT_UNSOLD",
						icon: <FcElectricity />,
						role: "POS_REPORT_PRODUCT_ACTION"
					},
				]
			},
			{
				icon: <FcSalesPerformance />,
				title: 'SETTINGS',
				show: false,
				role: "POS_SETTINGS",
				children: [
					{
						title: true,
						i18n: "FINANCE"
					},
					{
						url: "/wallet",
						i18n: "SAFE",
						icon: <FcSafe />,
						role: "POS_WALLET"
					},
					{
						url: "/bank",
						i18n: "BANK",
						icon: <FcBusiness />,
						role: "POS_BANK"
					},
					{
						url: "/salary",
						i18n: "SALARY",
						icon: <FcSalesPerformance />,
						role: "POS_SALARY"
					},
					{
						url: "/kpi",
						i18n: "KPI",
						icon: <FcSportsMode />,
						role: "POS_KPI"
					},
					{
						title: true,
						i18n: "SETTINGS"
					},
					{
						url: "/settings",
						i18n: "SETTINGS",
						icon: <FcSettings />,
						role: "POS_SETTINGS"
					},
					{
						url: "/pos",
						i18n: "POSES",
						icon: <FcShop />,
						role: "POS_SETTINGS_POS"
					},
					{
						url: "/cashbox",
						i18n: "POSES_CASHIER",
						icon: <FcPodiumWithSpeaker />,
						role: "POS_SETTINGS_POS_CASHBOX"
					},
				]
			},
		]
	}
]

export default pos