import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import { toast } from 'react-toastify'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import XLSX from 'xlsx-js-style';

import { SET_REPORT_SELECTED_CLIENT } from 'store/report/actions';
import { PGET, GET, POST } from 'helpers/api'
import { formatMoney, regexPhoneNumber, formatDateBackend, returnSign, formatDate, findFromArrayById } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const reduxAccount = useSelector(state => state.account)

	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 2, 'name': t('TERMINAL') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const paymentPurposes = [{ "id": 4, "name": "Долг" }, { "id": 5, "name": "Погашение задолженности" }]
	const [totalAmount, setTotalAmont] = useState([])
	const [data, setData] = useState([])
	const [initPoses, setInitPoses] = useState([])
	const [poses, setPoses] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [filterData, setFilterData] = useState({
		startDate: null,
		endDate: null,
		posId: reduxAccount.posId,
		paymentPurposeId: null,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/services/web/api/report-client-pageList',
		response: null
	})

	function redirectToInner(item) {
		dispatch(SET_REPORT_SELECTED_CLIENT(item))
		navigate(`/report/clients/debt`)
	}

	function redirectToSelectOnSale(item) {
		dispatch(SET_REPORT_SELECTED_CLIENT(item))
		navigate(`/report/clients/cheques`)
	}

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.posId = poses[0]['id']
		itemCopy.paymentTypeId = 1
		itemCopy.amountTypeId = 1
		itemCopy.currencyId = 1
		itemCopy.amountIn = ''
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var sendData = {}
		var response;
		sendData.currencyId = selectedItemCopy.currencyId
		sendData.clientId = selectedItemCopy.clientId
		sendData.paymentTypeId = selectedItemCopy.paymentTypeId
		sendData.posId = selectedItemCopy.posId
		sendData.note = selectedItemCopy.note
		console.log(selectedItemCopy.type);

		if (selectedItemCopy.type === 1) {
			sendData.amountIn = selectedItemCopy.amountIn
			response = await POST('/services/web/api/clients-payment', sendData, { loader: true })
		} else {
			sendData.amountOut = selectedItemCopy.amountOut
			response = await POST('/services/web/api/clients-debt', sendData, { loader: true })
		}

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	async function showPaymentModal(bool, type = 1) {
		var paymentDataCopy = {}
		if (!bool) {
			paymentDataCopy.paymentTypeId = 1
			paymentDataCopy.amountTypeId = 1
			paymentDataCopy.currencyId = 1
			paymentDataCopy.type = type
			if (type === 1) {
				paymentDataCopy.amountIn = ''
			} else {
				paymentDataCopy.amountOut = ''
			}
			setSelectedItem(paymentDataCopy)
		} else {
			if (!selectedItem?.clientId) {
				toast.warning(t('NO_CLIENT_SELECTED'))
				return
			}

			setSelectedItem({ ...selectedItem, type: type, posId: reduxAccount.posId })
		}

		setModalPayment(bool)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getData() {
		var filterUrl = ''
		const response = await PGET(`/services/web/api/report-client-pageList${filterUrl}`, {}, { loader: true })
		if (response) {
			setPagination({ ...pagination, 'url': "/services/web/api/report-client-pageList", 'response': response })
			setData(response.data)
		}

		const temporaryTotalAmount = await GET(`/services/web/api/report-client-total${filterUrl}`)
		setTotalAmont(temporaryTotalAmount)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setInitPoses([...response])
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/services/web/api/report-client-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.paymentPurposeId)
			urlParams += returnSign(urlParams) + 'paymentPurposeId=' + filterData.paymentPurposeId
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/web/api/report-client-pageList" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-client-excel" + urlParams
			const response = await GET(filterUrl)

			const borderStyle = {
				top: { style: "thin", color: { rgb: "000000" } },
				bottom: { style: "thin", color: { rgb: "000000" } },
				left: { style: "thin", color: { rgb: "000000" } },
				right: { style: "thin", color: { rgb: "000000" } }
			};

			// Define column widths
			const columnWidths = [
				{ wch: 4 },  // №
				{ wch: 15 }, // CLIENT
				{ wch: 13 }, // MOBILE
				{ wch: 13 }, // PHONE
				{ wch: 15 }, // NOTE
				{ wch: 10 }, // USD
				{ wch: 13 }  // Сум
			];

			// Define header styles
			const headerStyle = {
				font: { bold: true },
				border: borderStyle,
				alignment: { horizontal: "center", vertical: "center" }
			};

			// Define cell style
			const cellStyle = {
				font: { color: { rgb: "000000" } },
				border: borderStyle
			};

			const wrapTextStyle = {
				...cellStyle,
				alignment: { wrapText: true }
			};

			const additionalHeaders = [
				[
					{ v: "", s: headerStyle },
					{ v: t("TOTAL_AMOUNT"), s: headerStyle },
					{ v: "", s: headerStyle },
					{ v: "", s: headerStyle },
					{ v: "", s: headerStyle },
					{ v: totalAmount.find(item => item.currencyName === 'USD')?.totalAmount, s: headerStyle },
					{ v: totalAmount.find(item => item.currencyName === t('SUM'))?.totalAmount, s: headerStyle },
				],
			];

			const excelHeaders = [
				[
					{ v: "№", s: headerStyle },
					{ v: t('CLIENT'), s: headerStyle },
					{ v: t('MOBILE'), s: headerStyle },
					{ v: t('PHONE'), s: headerStyle },
					{ v: t('NOTE'), s: headerStyle },
					{ v: 'USD', s: headerStyle },
					{ v: 'Сум', s: headerStyle }
				],
				...additionalHeaders,
			];

			for (let i = 0; i < response.length; i++) {
				const excelItems = [];
				excelItems.push({ v: i + 1, s: cellStyle }); // Add row number
				excelItems.push({ v: response[i].name, s: wrapTextStyle });
				excelItems.push({ v: response[i].phone1, s: cellStyle });
				excelItems.push({ v: response[i].phone2, s: cellStyle });
				excelItems.push({ v: response[i].note !== null ? response[i].note : "", s: wrapTextStyle }); // Check for null note

				let usdValue = 0;
				let sumValue = 0;

				for (let j = 0; j < response[i]['balanceList'].length; j++) {
					if (response[i]['balanceList'][j]['currencyName'] === "USD") {
						usdValue = response[i]['balanceList'][j]['totalAmount'] || 0;
					}
					if (response[i]['balanceList'][j]['currencyName'] === t('SUM')) {
						sumValue = response[i]['balanceList'][j]['totalAmount'] || 0;
					}
				}

				excelItems.push({ v: usdValue, s: cellStyle });
				excelItems.push({ v: sumValue, s: cellStyle });

				excelHeaders.push(excelItems);
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			ws['!cols'] = columnWidths; // Set column widths
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('CLIENTS') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
			filterUrl = "/services/web/api/report-client-total" + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)

			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			paymentPurposeId: null,
			productCategoryId: null,
			groupBy: false,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.paymentPurposeId === null &&
			filterData.groupBy === false &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex mobile-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true, 1)}>
									{t('PAY')}
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true, 2)}>
									{t('DEBT')}
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => searchWithFilter(true)}>
									EXCEL
								</button>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('EXPENSE_TYPE')}</label>
												<Select
													options={paymentPurposes}
													value={paymentPurposes.find(option => option.id === filterData.paymentPurposeId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'paymentPurposeId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount?.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId ?
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
									:
									''
								}
								{filterData.paymentPurposeId &&
									<span className="me-2">
										<b>{t('EXPENSE_TYPE')}: </b> {findFromArrayById(paymentPurposes, filterData.paymentPurposeId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('CLIENT')}</th>
									<th>{t("MOBILE")}</th>
									<th>{t("PHONE")}</th>
									<th>{t("NOTE")}</th>
									<th className="text-center">{t("CUSTOMER_DEBT")}</th>
									<th className="text-center">{t("ACTION")}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr className={'tr-middle ' + (
										(selectedItem && selectedItem.clientId === item.clientId) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>
											<span className="text-primary cursor table-first-column-clickable"
												onClick={() => redirectToInner(item)}>
												{item.rowNum}. {item.name}
											</span>
										</td>
										<td>{regexPhoneNumber(item.phone1)}</td>
										<td>{regexPhoneNumber(item.phone2)}</td>
										<td>{item.note}</td>
										<td className="text-center">
											{item?.balanceList?.map((item, index) => (
												<div className={item.totalAmount > 0 ? 'text-success' : 'text-danger'} key={index}>
													<span className="me-1">{formatMoney(item.totalAmount)}</span>
													<b>{item.currencyName}</b>
												</div>
											))
											}
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="table-action-button table-action-primary-button me-2"
													title={t('DEBTOR')}
													onClick={() => redirectToInner(item)}>
													<i className="uil-credit-card-search"></i>
												</div>
												<div className="table-action-button table-action-primary-button"
													title={t('CHEQUES')}
													onClick={() => redirectToSelectOnSale(item)}>
													<i className="uil-receipt"></i>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}</label>
							<Select
								options={initPoses}
								value={initPoses.find(option => option.id === selectedItem.posId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'posId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={currencies}
								value={currencies.find(option => option.id === selectedItem.currencyId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						{selectedItem.type === 1 ?
							<div className="form-group">
								<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="amountIn" autoFocus step="any" value={selectedItem.amountIn}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': Number(e.target.value) > 0 ? e.target.value : selectedItem.amountIn })} />
							</div>
							:
							<div className="form-group">
								<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
								<input type="number" className="form-control" name="amountOut" autoFocus step="any" value={selectedItem.amountOut}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'amountOut': Number(e.target.value) > 0 ? e.target.value : selectedItem.amountOut })} />
							</div>
						}
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100" disabled={selectedItem.type === 1 ? !selectedItem.amountIn : !selectedItem.amountOut}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
