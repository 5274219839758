import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from "pages/parts/useDidMountEffect"

import { Modal, Dropdown } from 'react-bootstrap'
import Search from 'pages/parts/Search'
import Select from 'react-select'

import { Controller, useForm } from "react-hook-form"

import { GET, PGET, POST, PUT } from 'helpers/api'
import { checkRole, findFromArrayById, formatMoney } from 'helpers/helpers'
import { Link } from "react-router-dom"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const operationTypes = [{ "id": 1, "name": t('IN_BALANCE') }, { "id": 2, "name": t('OUT_BALANCE') }]

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange"
	});
	const { errors } = formState

	const reduxAccount = useSelector(state => state.account)

	const canCreate = checkRole('POS_WALLET_ADD')
	// const canUpdate = checkRole('POS_WALLET_EDIT')

	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)

	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
		page: 0,
		random: 0
	})

	const [selectedItem, setSelectedItem] = useState({
		operationType: 2,
		amount: '',
		note: '',
	})

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [wallets, setWallets] = useState([])

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var sendData = {}
		sendData.walletId = selectedItemCopy.walletId
		sendData.operationType = selectedItemCopy.operationType
		sendData.amount = selectedItemCopy.amount
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/web/api/wallet-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	function showPaymentModal(bool) {
		if (!bool) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.operationType = 2
			selectedItemCopy.amount = ''
			selectedItemCopy.note = ''
			setSelectedItem(selectedItemCopy)
		}
		console.log(selectedItem);
		setModalPayment(bool)
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/wallet', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/wallet', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	// function openModal(item) {
	// 	var itemCopy = { ...item }
	// 	for (const [key, value] of Object.entries(itemCopy)) {
	// 		setValue(key, value)
	// 	}
	// 	setValue('id', item.walletId)
	// 	setShowModal(true)
	// }

	function closeModal() {
		setShowModal(false)
		reset()
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		var filterDataCopy = { ...filterData }
		const response = await PGET('/services/web/api/wallet-list', filterDataCopy, { loader: true })
		if (response) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			setPoses(response)
		}
	}

	async function getWallets() {
		const response = await GET("/services/web/api/wallet-helper")
		if (response) {
			setWallets(response)
			setSelectedItem({ ...selectedItem, walletId: response[0]?.walletId })
		}
	}

	async function search(data) {
		setData(data)
	}

	useEffect(() => {
		getData()
		getPoses()
		getWallets()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'documentsIn', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-between mb-3">
						<div className="d-flex">
							<div className="me-2">
								<Search url={'/services/web/api/clients-pageList'} search={search} getData={getData}></Search>
							</div>
							<div>
								<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-12">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-outline-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
														{t('APPLY')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={showPaymentModal}>
									{t('PAY')}
								</button>
							</div>
						</div>

						{canCreate &&
							<button className="btn btn-outline-primary" onClick={() => setShowModal(true)}>
								{t('CREATE')}
							</button>
						}
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="row mt-3">
				{data?.map((item, index) => (
					<Link to={'/wallet/history/' + item.walletId} className="col-md-4 mb-3 text-black" key={index}>
						<div className="card border-radius-3 shadow">
							<div className="card-body d-flex align-items-center gap-3 px-4 cursor">
								{/* <i className="uil-wallet" style={{ fontSize: '50px' }}></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="safe-box" width={80} height={80}>
									<defs>
										<linearGradient id="a" x1="7.853" x2="24.147" y1="30.111" y2="1.889" gradientUnits="userSpaceOnUse">
											<stop offset="0" stopColor="#4c46c8"></stop>
											<stop offset="1" stopColor="#87bfff"></stop>
										</linearGradient>
									</defs>
									<path fill="url(#a)" d="M27 4H5a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm-3 18H8V10h16Zm2-10h2v3h-2Zm0 5h2v3h-2Zm1 9H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v3h-2a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2h2v3a1 1 0 0 1-1 1ZM14 15a1.994 1.994 0 0 1-1 1.723V19a1 1 0 0 1-2 0v-2.277A2 2 0 1 1 14 15Z"></path>
								</svg>

								<div className="w-100">
									<h4>{item.walletName}</h4>
									<div className="d-between mt-2 mb-1">
										<div>{t('POS')}</div>
										<div>{item.posName}</div>
									</div>
									<div className="d-between mb-1">
										<div>{t('BALANCE')}</div>
										<b>{formatMoney(item.walletBalance)} <small>{item.currencyName}</small></b>
									</div>
								</div>
							</div>
						</div>
					</Link>
				))
				}
			</div>


			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('id')}
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label>{t('TITLE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="walletName" {...register('walletName')} />
							<span className="text-danger text-sm">{errors.walletName?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
							<Controller
								name="currencyId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={currencies}
										value={currencies.find(option => option.id === getValues('currencyId')) || ''}
										onChange={(e) => setValue('currencyId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('id')}
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label>{t('BALANCE')}</label>
							<input type="number" className="form-control" name="walletBalance" {...register('walletBalance')} disabled={getValues('id')} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('WALLET')}<span className="required-mark">*</span></label>
							<Select
								options={wallets}
								value={wallets.find(option => option.walletId === selectedItem.walletId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'walletId': option.walletId })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.walletName}
								getOptionValue={(option) => option.walletId}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}<span className="required-mark">*</span></label>
							<Select
								options={operationTypes}
								value={operationTypes.find(option => option.id === selectedItem.operationType)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'operationType': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount" autoFocus
								value={selectedItem.amount}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100" disabled={!selectedItem.amount || !selectedItem.note}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
