import React, { useEffect, useState } from "react"
import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { DebounceInput } from 'react-debounce-input'
import { toast } from 'react-toastify'

import { GET, POST, PUT } from 'helpers/api'
import { formatMoney, formatDate, findIdFromArray } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [poses, setPoses] = useState([])
	const [barcode, setBarcode] = useState(false)
	const [search, setSearch] = useState('')
	const [searchDataExist, setSearchDataExist] = useState(false)
	const [searchData, setSearchData] = useState([])
	const [data, setData] = useState({
		productList: [],
		wmsId: null,
		organizationId: null,
		paymentTypeId: 2,
		totalAmount: 0,
	})

	async function create(e) {
		e.preventDefault();
		var sendData = { ...data }

		for (let i = 0; i < sendData.productList.length; i++) {
			if (!sendData.productList[i]['quantity']) {
				sendData.error = true
			}
			if (!(sendData.productList[i]['quantity'] <= sendData.productList[i]['balance'])) {
				sendData.error = true
			}
		}

		if (sendData.error) {
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
			return
		}

		var response;
		if (sendData.id) {
			response = await PUT('/services/wms/api/write-off', sendData, { loader: true })
		} else {
			response = await POST('/services/wms/api/write-off', sendData, { loader: true })
		}

		if (response) {
			navigate('/wms/write-off')
		}
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await GET(
				"/services/wms/api/write-off-list",
				{
					'name': value, 'wmsId': data.wmsId, 'barcode': barcode,
				},
				{ loader: true }
			)

			var dataCopy = { ...data }
			var responseCopy = [...response]
			var responseCopy2 = [...response]
			for (let i = 0; i < responseCopy.length; i++) {
				for (let j = 0; j < dataCopy.productList.length; j++) {
					if (
						responseCopy[i]['productId'] ===
						dataCopy.productList[j]['productId']
					) {
						if (responseCopy.length === 1) {
							setSearchDataExist(2)
							setSearch('')
						}
						responseCopy2 = responseCopy2.filter(e => e.productId !== dataCopy.productList[j]['productId'])
					}
				}
			}

			if (responseCopy2.length === 0) {
				setSearchDataExist(1)
				return
			}

			if (/^\d+$/.test(value) && responseCopy2.length === 1) {
				setSearchData(responseCopy2)
				if (responseCopy2[0]['productShow']) {
					addToList(0, responseCopy2)
				}
				setSearch('')
			} else {
				setSearchData(responseCopy2)
			}
		}
	}

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value
		setData(dataCopy)
		countTotalAmount()
	}

	function countTotalAmount() {
		var dataCopy = { ...data }
		dataCopy.totalAmount = 0
		for (let i = 0; i < dataCopy.productList.length; i++) {
			if (dataCopy.productList[i]['price'] && dataCopy.productList[i]['quantity']) {
				dataCopy.totalAmount += dataCopy.productList[i]['price'] * dataCopy.productList[i]['quantity']
				dataCopy.productList[i]['totalAmount'] = dataCopy.productList[i]['price'] * dataCopy.productList[i]['quantity']
			}
		}

		setData(dataCopy)
	}

	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchData]
		}
		searchDataCopy[index].quantity = ''

		var dataCopy = { ...data }
		dataCopy.productList.push(searchDataCopy[index])

		setData(dataCopy)

		searchDataCopy.splice(index, 1)
		setSearchData(searchDataCopy)

		if (searchDataCopy.length === 0) {
			setSearch('')
		}
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		dataCopy.productList.splice(index, 1)
		setData(dataCopy)
		countTotalAmount()
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/wms/api/wms-helper')
		if (temporaryPoses) {
			setPoses(temporaryPoses)
		}

		if (temporaryPoses.length > 0) {
			if (
				reduxSettings.wmsId &&
				findIdFromArray(temporaryPoses, reduxSettings.wmsId)
			) {
				setData({
					...data,
					'wmsId': reduxSettings.wmsId,
					'currencyId': findIdFromArray(currencies, reduxSettings.currencyId) ? findIdFromArray(currencies, reduxSettings.currencyId) : 1
				})
				return;
			}
			setData({
				...data, 'wmsId': temporaryPoses[0]['id'],
				'currencyId': findIdFromArray(currencies, reduxSettings.currencyId) ? findIdFromArray(currencies, reduxSettings.currencyId) : 1
			})
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('WRITE_OFF')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('POS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={data.productList.length > 0}
										options={poses}
										value={poses.find(option => option.id === data.wmsId)}
										onChange={(option) => setData({ ...data, 'wmsId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>

							<div className="col-md-2">
								<div className="form-group">
									<label>{t('WRITE_OFF')} №</label>
									<input name="writeOffNumber" className="form-control" onChange={(e) => setData({ ...data, 'writeOffNumber': e.target.value })} />
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('NOTE')}</label>
									<input name="note" className="form-control" onChange={(e) => setData({ ...data, 'note': e.target.value })} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th>{t('NAME_OF_PRODUCT')}</th>
										<th className="text-center">{t('BARCODE')}</th>
										<th className="text-center">{t('EXPIRATION_DATE')}</th>
										<th className="text-center">{t('IN_THE_WAREHOUSE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center">{t('ADMISSION_PRICE')}</th>
										<th className="text-center">{t('TOTAL_AMOUNT')}</th>
										<th className="text-center">{t('ACTION')}</th>
									</tr>
								</thead>
								<tbody>
									{data.productList.map((item, index) => (
										<tr key={index}>
											<td className="custom-truncate" style={{ 'width': '20%' }}>
												{index + 1}. {item.productName}
											</td>
											<td className="text-center">{item.barcode}</td>
											<td className="text-center">{formatDate(item.expDate)}</td>
											<td className="text-center">{item.balance} {item.uomName}</td>
											<td className="text-center">
												<input type="number" step="0.01" min="0" name="quantity"
													className={"auto-width-input " + ((!item.quantity || item.quantity > item.balance) && "required-border")}
													value={item.quantity}
													onChange={(e) => handleProductListChange(e, index)}
												/>
											</td>
											<td className="text-center text-no-wrap">{formatMoney(item.price)}</td>
											<td className="text-center text-no-wrap">{formatMoney(item.quantity * item.price)}</td>
											<td>
												<div className="d-flex justify-content-center" onClick={() => deleteProduct(index)}>
													<div className="table-action-button table-action-danger-button">
														<i className="uil uil-times"></i>
													</div>
												</div>
											</td>
										</tr>
									))
									}
								</tbody>
							</table>
						</div>

						<div className="row">
							<div className="col-md-6 vertical-center">
								<div className="form-group position-relative m-0">
									<DebounceInput
										disabled={!data.wmsId}
										value={search}
										onChange={(e) => searchProduct(e.target.value)}
										onKeyUp={(e) => {
											if (e.keyCode === 13) {
												searchProduct(e.target.value)
											}
										}}
										type="text"
										className="form-control"
										placeholder={t('SEARCH') + '...'}
										debounceTimeout={1000}
									/>

									{searchData.length > 0 ?
										<i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch('') }}></i>
										:
										<i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
									}

									{searchData.length > 0 &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list">
												<span className="dropdown-menu-result">
													<div className="table-responsive p-3">
														<table className="table-striped table">
															<thead>
																<tr>
																	<th>{t('NAME_OF_PRODUCT')}</th>
																	<th className="text-center">{t('BARCODE')}</th>
																	<th className="text-center">{t('EXPIRATION_DATE')}</th>
																	<th className="text-center">{t('RESIDUE')}</th>
																	<th className="text-center">{t('ACTION')}</th>
																</tr>
															</thead>
															<tbody>
																{searchData.map((item, index) => (
																	<tr key={index}>
																		<td>{item.productName}</td>
																		<td className="text-center">{item.barcode}</td>
																		<td className="text-center">{item.expDate}</td>
																		<td className="text-center">{item.balance} {item.uomName}</td>
																		<td>
																			<div className="table-actions">
																				<div className="table-action-button table-action-primary-button" onClick={() => addToList(index)}>
																					<i className="uil uil-plus"></i>
																				</div>
																			</div>
																		</td>
																	</tr>
																))
																}
															</tbody>
														</table>
													</div>
												</span>
											</div>
										</span>
									}

									{(searchData.length === 0 && searchDataExist === 1) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
											</div>
										</span>
									}
									{(searchData.length === 0 && searchDataExist === 2) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_ADDED')}</span>
											</div>
										</span>
									}

								</div>
							</div>
							<div className="col-md-6 d-flex justify-content-end">
								<h5 className="d-flex justify-content-between"><strong className="me-2">{t('RECEIPT_AMOUNT')}:</strong> {formatMoney(data.totalAmount)}</h5>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-outline-danger btn-wide me-2" to="/wms/documents-out">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-outline-primary btn-wide"
								disabled={!data.currencyId}
								onClick={create}>{t('SAVE')}</button>
						</div>
					</div>
				</div>

			</form>
		</>
	)
}

export default CreateUpdate

