import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

import { GUESTPOST, GET } from 'helpers/devApi'
import { clearTemporaryStorage } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz-Latn-UZ.png'
import uzCyrl from 'assets/images/flags/uz-Cyrl-UZ.png'

import logo from 'assets/images/logo.svg'
import ForgotPassword from "./ForgotPassword";
import Register from "./Register";

function Login() {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const [user, setUser] = useState({ "username": "" })
	const [activeTab, setActiveTab] = useState(1)
	const [showPassword, setShowPassword] = useState(false)

	async function handleSubmit(e) {
		e.preventDefault();
		var sendData = {
			username: user.username.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		const response = await GUESTPOST('/auth/login', sendData, { loader: true })
		if (response) {
			if (!response.activated) {
				toast.error(t('ERROR') + ': ' + t('YOUR_ACCOUNT_BLOCKED'))
				return
			}
			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('access_token', response.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem("authUser", JSON.stringify(user))

			const account = await GET('/services/uaa/api/account')
			localStorage.setItem("authorities", JSON.stringify(account.authorities));
			dispatch({ type: 'SET_ACCOUNT', payload: { ...account, ...response } })

			var checker = false
			for (let i = 0; i < account.authorities.length; i++) {
				if (account.authorities[i] === "ROLE_OWNER") {
					var role = "ROLE_OWNER"
					checker = true
				}
				if (account.authorities[i] === "ROLE_MERCHANDISER") {
					role = "ROLE_MERCHANDISER"
					checker = true
				}
				if (account.authorities[i] === "ROLE_ORGANIZATION") {
					role = "ROLE_ORGANIZATION"
					checker = true
				}
				if (account.authorities[i] === "ROLE_DIRECTOR") {
					role = "ROLE_DIRECTOR"
					checker = true
				}
				localStorage.setItem("role", role);
			}
			if (checker === true) {
				const temporarySettings = await GET("/services/web/api/user-settings")
				if (temporarySettings.settings) {
					const parsedSettings = JSON.parse(temporarySettings.settings)
					dispatch({ type: 'SET_SETTINGS', payload: parsedSettings })
				}
				switch (role) {
					case "ROLE_MERCHANDISER":
						navigate("/documents-in")
						break;
					case "ROLE_ORGANIZATION":
						navigate("/organization-balance")
						break;
					case "ROLE_OWNER":
						navigate("/dashboard")
						break;
					default:
						navigate("/dashboard")
						break;
				}
			} else {
				toast.error(t('ERROR') + ': ' + t('YOU_DO_NOT_HAVE_ACCESS'))
				clearTemporaryStorage()
			}
		}

	}

	function changeLanguage(language = 'uz-Latn-UZ') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz-Latn-UZ') {
			localStorage.setItem('lang', 'uz-Latn-UZ');
		}
		if (language === 'uz-Cyrl-UZ') {
			localStorage.setItem('lang', 'uz-Cyrl-UZ');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'RUSSIAN' }
		else if (locale === 'uz-Latn-UZ') return { flag: uzLatn, lang: 'UZBEK_LATN' }
		else if (locale === 'uz-Cyrl-UZ') return { flag: uzCyrl, lang: 'UZBEK_CYRL' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages">
					<div className="auth-card">
						<div className={"auth_tabs " + (i18n.language)}>
							<div className={"auth_tab " + (activeTab === 1 ? 'active' : '')} onClick={() => setActiveTab(1)}>
								{t('LOGIN')}
							</div>
							<div className={"auth_tab " + (activeTab === 2 ? 'active' : '')} onClick={() => setActiveTab(2)}>
								{t('FORGOT_PASSWORD')}
							</div>
							<div className={"auth_tab " + (activeTab === 3 ? 'active' : '')} onClick={() => setActiveTab(3)}>
								{t('REGISTER')}
							</div>
						</div>

						<div className="auth-content">
							<div className="mb-3">
								<img src={logo} alt="" height={30} />
							</div>

							<div className="d-flex justify-content-around align-items-center">
								{activeTab !== 2 && <div className="auth-devider" />}
								<div className="auth-header">
									{activeTab === 1 && t('LOGGING_IN')}
									{activeTab === 2 && t('RECOVER_YOUR_PASSWORD')}
									{activeTab === 3 && t('REGISTER')}
								</div>
								{activeTab !== 2 && <div className="auth-devider" />}
							</div>

							{activeTab === 1 &&
								<>
									<div className="p-2">
										<form className="form-horizontal" autoComplete="off" onSubmit={handleSubmit}>

											<div className="input-group login-group mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="uil-user"></i>
													</span>
												</div>
												<input type="text" className="form-control login-input" placeholder={t('LOGIN')}
													autoFocus name="username" value={user.username}
													onChange={(e) => setUser({ ...user, 'username': e.target.value.toLowerCase()?.replace(/[^\w\s-_.!]/gi, '')?.replace(/ /g, '') })} />
											</div>

											<div className="input-group login-group mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="uil-lock-alt"></i>
													</span>
												</div>
												<input type={showPassword ? "text" : "password"} className="form-control login-input second" placeholder={t('PASSWORD')}
													name="password" value={user.password}
													onChange={(e) => setUser({ ...user, 'password': e.target.value })} />
												<div className="input-group-append">
													<span className="input-group-text second cursor" onClick={() => setShowPassword(!showPassword)}>
														{showPassword ?
															<i className="uil-eye"></i>
															:
															<i className="uil-eye-slash"></i>
														}
													</span>
												</div>
											</div>

											<div className="text-center">
												<button type="submit" className="login-button">
													{t('ENTER')}
												</button>
											</div>
										</form>
									</div>
								</>
							}
							{activeTab === 2 &&
								<ForgotPassword />
							}
							{activeTab === 3 &&
								<Register />
							}
						</div>
					</div>

					<div className="auth-welcome">
						{/* <img src={logo_white} alt="" width={200} /> */}
						{/* {t('WELCOME')} */}
					</div>

					<div className="position-absolute lang-position">
						<Dropdown className="cursor-pointer d-flex cursor">
							<Dropdown.Toggle as={LanguageToggle}>
								<div className="d-flex">
									<div className="vertical-center">
										<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
									</div>
									<span>{t(getCurrentLocale().lang)}</span>
								</div>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{i18n.language !== 'ru' &&
									<Dropdown.Item onClick={() => changeLanguage("ru")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
											<span>{t('RUSSIAN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz-Latn-UZ' &&
									<Dropdown.Item onClick={() => changeLanguage("uz-Latn-UZ")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz-Latn-UZ" width="24" height="16" /></div>
											<span>{t('UZBEK_LATN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz-Cyrl-UZ' &&
									<Dropdown.Item onClick={() => changeLanguage("uz-Cyrl-UZ")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz-Cyrl-UZ" width="24" height="16" /></div>
											<span>{t('UZBEK_CYRL')}</span>
										</div>
									</Dropdown.Item>
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="about-mdokon position-absolute">
						{t('VERSION')}: 1 <br />
						{t('CONTACT_CENTRE')}: +998 55 500-00-89 <br />
						{t('MADE_BY_AS')} <br />
						{t('LEGAL_ADDRESS')} <br />
						{t('MADE_BY_AS2')}
					</div>
				</div>
			</div>
		</>
	)
}

export default Login;
