import React, { useState } from "react"
import { useTranslation } from 'react-i18next'

import { toast } from "react-toastify";

function ForgotPassword() {
	const { t } = useTranslation();

	const [user, setUser] = useState({
		name: "",
		phone: "",
	})

	async function sendRequest() {
		var token = "5514555123:AAEYpZr71lt6DrV7VJXQCotkoUy9aqUt4QQ";
		var chatId = "-1001503556211";

		if (user.phone.length !== 9) {
			toast.error('Неправильный формат телефона')
			return;
		}

		var text = '🎉 Новая заявка с сайта: cabinet.mdokon.uz ! \n\n'
		text += `Имя: *${user.name}*\n`
		text += `Телефон: *+998${user.phone}*`
		const response = await fetch(`https://api.telegram.org/bot${token}/sendMessage`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				chat_id: chatId,
				parse_mode: 'MarkDown',
				text,
			}),
		})
		if (response.ok) {
			toast.success('Заявка отправлена')
			setUser({ name: '', phone: '' })
		}
	}

	return (
		<>
			<div>
				<div className="p-2">

					<div className="input-group login-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i className="uil-user"></i>
							</span>
						</div>
						<input type="text" className="form-control login-input" placeholder={t('NAME')}
							autoFocus name="name" value={user.name}
							onChange={(e) => setUser({ ...user, 'name': e.target.value?.replace(/[^\w\s]/gi, '') })} />
					</div>

					<div className="input-group login-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text" style={{ fontSize: '14px' }}>
								+998
							</span>
						</div>
						<input type="text" className="form-control login-input" placeholder={t('PHONE')}
							autoFocus name="phone" value={user.phone}
							onChange={(e) => setUser({ ...user, 'phone': e.target.value.replace(/[^0-9.]/g, '') })} />
					</div>

					<div className="text-center">
						<button type="button" className="login-button" onClick={() => sendRequest()}>
							{t('SEND_REQUEST')}
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword
