import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { SET_LOCK_SCREEN } from 'store/settings/actions'
import { useTranslation } from 'react-i18next'
//import io from 'socket.io-client'

// Reactstrap
import { Modal } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../topbarDropdown/LanguageDropdown";

import { showSidebar, hideSidebar } from "store/actions";
import { POST, GET } from 'helpers/api'
import { clearTemporaryStorage, formatMoney } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch()

	//const socketRef = useRef()
	const wrapperRef = useRef(null);

	const layoutSelector = useSelector(state => state.Layout)
	const reduxAccount = useSelector(state => state.account)

	const role = localStorage.getItem('role')

	const [modalLogout, setModalLogout] = useState(false)
	const [showUserDropdown, setShowUserDropdown] = useState(false)
	const [balance, setBalance] = useState(0)
	const [headers, setHeaders] = useState([])

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function lockScreen() {
		dispatch(SET_LOCK_SCREEN())
	}

	function tToggle() {
		if (layoutSelector.showSidebar) {
			dispatch(showSidebar());
		} else {
			dispatch(hideSidebar());
		}
	}

	async function logout() {
		if (process.env.NODE_ENV === 'development') {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			window.location.reload()
			return
		}
		const response = await POST('/auth/logout')
		if (response) {
			clearTemporaryStorage()
			navigate('/auth/login')
			dispatch({ type: 'SET_DOCUMENT_IN_CREATE_DATA', payload: [] })
			window.location.reload()
		}
	}

	async function getBalance() {
		const response = await GET('/services/web/api/pos-balance')
		setBalance(response)
	}

	// function socketConnection() {
	// 	console.log('a1')
	// 	const baseURL = "http://cabinet.mdokon.uz:9501/online-users";
	// 	socketRef.current = io.connect(baseURL, {
	// 		transports: ['websocket'],
	// 		query: {
	// 			"login": JSON.parse(localStorage.getItem('authUser')).username,
	// 			"Authorization": localStorage.getItem('access_token')
	// 		}
	// 	});

	// 	socketRef.current.on('connect', function () {
	// 		console.log('Connected', socketRef);
	// 		console.log('Connected', socketRef.connected);
	// 	});

	// 	socketRef.current.on('disconnect', function () {
	// 		console.log('Disconnected');
	// 	});


	// 	socketRef.current.on('online-users', (data) => {
	// 		console.log(data);
	// 	})
	// }

	useEffect(() => {
		//socketConnection()
		if (role !== 'ROLE_ORGANIZATION') {
			getBalance()
		}

		// return () => {
		// 	socketRef?.current?.disconnect()
		// }
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShowUserDropdown(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	useEffect(() => {
		var arr = location.pathname.replaceAll('-', '_').replaceAll('%', ' ').split('/')
		arr.shift();
		arr = arr.filter(item => isNaN(item));
		console.log(location);

		setHeaders(arr)
	}, [location]);

	return (
		<>
			<header id="page-topbar" className={layoutSelector.showSidebar ? 'sidebar-left' : 'sidebar-active-left'}>
				<div className="navbar-header">
					<div className="d-flex">
						<button type="button" id="vertical-menu-btn"
							className="btn btn-sm px-3 header-item waves-effect vertical-menu-btn"
							onClick={tToggle}>
							<i className="uil-bars fz-20" />
						</button>
						<div className="d-flex justify-content-between align-items-center navbar-breadcrumb">
							{headers.map((item, index) => (
								<React.Fragment key={index}>
									{index === 0 ?
										<h4 className="vertical-center">
											{t(item.toUpperCase())}
										</h4>
										:
										<h5 className="vertical-center">
											{t(item.toUpperCase())}
										</h5>
									}
									{headers[index + 1] &&
										<i className="uil uil-angle-double-right fz-20 mx-1"></i>
									}
								</React.Fragment>
							))
							}
						</div>
					</div>


					<div className="d-flex">

						<a className="navbar-phone" href='https://t.me/posyordam' target="_blank">
							<h5>{t('PHONE')}: +998 55 500 00 89</h5>
						</a>

						<LanguageDropdown />

						<button type="button"
							onClick={() => toggleFullscreen()}
							className="btn header-item noti-icon mobile-none"
							data-toggle="fullscreen">
							<i className="uil-minus-path"></i>
						</button>
						<button type="button"
							onClick={() => lockScreen()}
							className="btn header-item noti-icon mobile-none"
							data-toggle="fullscreen">
							<i className="uil-lock"></i>
						</button>
						<div className="d-lg-inline-block ms-1 dropdown" >
							<button type="button" className="btn header-item noti-icon waves-effect" ref={wrapperRef}>
								<div className={`profile-dropdown ${balance < 0 && 'bounce-effect'}`}>
									<div className={`profile-dropdown-toggle ${(balance > 0 ? 'success' : 'danger')}`}
										onClick={() => setShowUserDropdown(!showUserDropdown)}>
										<i className="uil uil-user" color="#fff"></i>
									</div>
									{showUserDropdown &&
										<div className="profile-dropdown-menu">
											<div className="profile-dropdown-item">
												<div>{t('NAME')}:</div>
												<b>{reduxAccount.first_name}</b>
											</div>
											<div className="profile-dropdown-item">
												<div>{t('LOGIN')}:</div>
												<b>{reduxAccount.login}</b>
											</div>
											<div className="profile-dropdown-item">
												<div>ID:</div>
												<b>{reduxAccount.posId}</b>
											</div>
											<div className="profile-dropdown-item">
												<div>{t('BALANCE')}:</div>
												<b className={`${balance < 0 && 'text-danger'}`}>
													{formatMoney(balance, 0)}
												</b>
											</div>
											{reduxAccount.role === 'ROLE_OWNER' &&
												<div className="profile-dropdown-item second"
													onClick={() => {
														navigate('/settings')
														setShowUserDropdown(false)
													}}>
													{t('SETTINGS')}
													<i className="uil-cog"></i>
												</div>
											}
											<div className="profile-dropdown-item second d-none"
												onClick={() => {
													navigate('/payment')
													setShowUserDropdown(false)
												}}>
												{t('PAYMENT')}
												<i className="uil-transaction"></i>
											</div>
											<div className="profile-dropdown-item second"
												onClick={() => setModalLogout(true)}>
												<div>
													{t('LOGOUT')}
												</div>
												<i className="uil-signin "></i>
											</div>
										</div>
									}
								</div>
							</button>
						</div>
					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-outline-danger w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-outline-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
