import React, { useEffect, useState } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import TableControl from 'pages/parts/TableControl'

import { GET, PUT } from 'helpers/api'
import { formatDate, formatMoney } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const reduxSettings = useSelector(state => state.settings)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const deliveryStatuses = [{ 'id': 1, 'name': t('NOT_DELIVERED') }, { 'id': 2, 'name': t('DELIVERED') }]

	const [poses, setPoses] = useState([])
	const [wms, setWms] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [data, setData] = useState({
		productList: [],
		posId: null,
		totalAmount: 0,
		deliveryStatus: 1,
		status: 0,
	})

	async function saveComplete() {
		//const response = 
		await PUT('/services/wms/api/order-pos-closed', data, { loader: true })
		// if (response && response?.success) {
		// 	navigate('/orders')
		// }
		const temporaryData = await GET('/services/wms/api/order-pos/' + id)
		setData(temporaryData)

		setModalConfirm(false)
	}

	function transfer() {
		dispatch({ type: 'SET_DOCUMENT_IN_PRODUCT_LIST', payload: data })
		navigate('/wms/wms-transfer/create')
	}

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value

		if (e.target.name === 'quantity') {
			var temporaryTotalAmount = 0

			for (let i = 0; i < dataCopy.productList.length; i++) {
				if (dataCopy.productList[i]['price'] && dataCopy.productList[i]['quantity']) {
					temporaryTotalAmount += data.productList[i]['price'] * data.productList[i]['quantity']
					dataCopy.totalAmount = temporaryTotalAmount
				} else {
					dataCopy.totalAmount = 0
				}
			}
		}

		setData(dataCopy)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		setPoses(response)

		if (id) {
			const temporaryData = await GET('/services/wms/api/order-pos/' + id)
			setData(temporaryData)
		}
	}

	async function getWms() {
		const response = await GET(`/services/web/api/wms-helper`)
		setWms(response)
	}

	async function getOrganizations() {
		const response = await GET(`/services/web/api/organization-helper`)
		setOrganizations(response)
	}

	useEffect(() => {
		getPoses()
		getWms()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('POS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={poses}
										value={poses.find(option => option.id === data.posId)}
										onChange={(option) => setData({ ...data, 'posId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('WAREHOUSE')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={wms}
										value={wms.find(option => option.id === data.wmsId) || ''}
										onChange={(option) => setData({ ...data, 'wmsId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={currencies}
										value={currencies.find(option => option.id === data.currencyId)}
										onChange={(option) => setData({ ...data, 'currencyId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('STATUS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={deliveryStatuses}
										value={deliveryStatuses.find(option => option.id === data.deliveryStatus)}
										onChange={(option) => setData({ ...data, 'deliveryStatus': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('ORGANIZATION')}</label>
									<Select
										isDisabled={!(data.status === 0)}
										options={organizations}
										value={organizations.find(option => option.id === data.organizationId) || ''}
										onChange={(option) => setData({ ...data, 'organizationId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('PAYMENT_METHOD')}</label>
									<Select
										isDisabled={!(data.status === 0)}
										options={paymentTypes}
										value={paymentTypes.find(option => option.id === data.paymentTypeId) || ''}
										onChange={(option) => setData({ ...data, 'paymentTypeId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th>{t('NAME_OF_PRODUCT')}</th>
										<th className="text-center">{t('BARCODE')}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
											<th className="text-center">{t('ARTIKUL')}</th>
										}
										<th className="text-center">{t('UNIT')}.</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
											<th className="text-center">{t('SERIAL')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
											<th className="text-center">{t('EXPIRATION_DATE')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
											<th className="text-center">{t('VAT')}</th>
										}
										<th className="text-center">{t("PRICE")}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
											<th className="text-center">{t('WHOLESALE_PRICE')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
											<th className="text-center">{t('BANK_PRICE')}</th>
										}
										<th className="text-center">{t('UNIT_PRICE')}</th>
										<th className="text-center">{t('RESIDUE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center table-control">
											<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
											{t('ACTION')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.productList?.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}. {item.name}</td>
											<td className="text-center">{item.barcode}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
												<td className="text-center">{item.productArtikul}</td>
											}
											<td className="text-center">{item.uomName}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
												<td className="text-center">{item.serial}</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
												<td className="text-center">{formatDate(item.expDate)}</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
												<td className="text-center">{item.vat}</td>
											}
											<td className="text-center">
												{data.status === 2 ?
													<span>{item.price}</span>
													:
													<input type="number" className={"auto-width-input " + (!item.price && "required-border")}
														name="price" value={item.price}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												}
											</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
												<td className="text-center">
													{data.status === 2 ?
														<span>{item.wholesalePrice}</span>
														:
														<input type="number" className={"auto-width-input " + (!item.wholesalePrice && "required-border")}
															name="wholesalePrice" value={item.wholesalePrice}
															onChange={(e) => handleProductListChange(e, index)}
														/>
													}
												</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
												<td className="text-center">
													{data.status === 2 ?
														<span>{item.bankPrice}</span>
														:
														<input type="number" className={"auto-width-input " + (!item.bankPrice && "required-border")}
															name="bankPrice" value={item.bankPrice}
															onChange={(e) => handleProductListChange(e, index)}
														/>
													}
												</td>
											}
											<td className="text-center">
												{data.status === 2 ?
													<span>{item.salePrice}</span>
													:
													<input type="number" className={"auto-width-input " + (!item.salePrice && "required-border")}
														name="salePrice" value={item.salePrice}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												}
											</td>
											<td className="text-center">
												<span>{item.fromWmsBalance}</span>
											</td>
											<td className="text-center">
												{data.status === 2 ?
													<span>{item.quantity}</span>
													:
													<input type="number" className={"auto-width-input " + (!item.quantity && "required-border")}
														name="quantity" value={item.quantity}
														onChange={(e) => {
															handleProductListChange(e, index)
														}}
													/>
												}
											</td>
											<td>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="row">
							<div className="col-md-6"></div>
							<div className="col-md-6 d-flex justify-content-end">
								<div className="flex-column">
									<h5 className="d-flex justify-content-between">
										<strong className="me-2">{t('TOTAL')}:</strong> {formatMoney(data.totalAmount)}
									</h5>
								</div>
							</div>
						</div>

						<div className="btn-wrapper justify-content-between">
							<div>
								{data.deliveryStatus === 2 &&
									<h2><b>{t('DELIVERED')}</b></h2>
								}
							</div>
							<div>
								<Link className="btn btn-outline-danger btn-wide ms-2" to="/wms/orders">
									{t('CANCEL')}
								</Link>
								{data.status === 2 &&
									<button type="button" className="btn btn-success btn-rounded btn-wide ms-2"
										onClick={() => transfer()}>
										{t('DISPLACEMENT')}
									</button>
								}
								{data.status !== 2 &&
									<button type="button" className="btn btn-success btn-rounded btn-wide ms-2"
										disabled={!data?.productList?.length > 0}
										onClick={() => setModalConfirm(true)}>
										{t('ACCEPT')}
									</button>
								}
							</div>
						</div>
					</div>
				</div>
			</form>

			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT')}?
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-outline-danger w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-outline-primary w-100" onClick={() => saveComplete(true)}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableTransfer'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default CreateUpdate

