import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST } from 'helpers/api'

function ForgotPassword() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [showActivationCode, setShowActivationCode] = useState(true)
	const [showPassword, setShowPassword] = useState(false)
	const [showPassword2, setShowPassword2] = useState(false)
	const [user, setUser] = useState({
		ownerLogin: ""
	})

	async function getActivationCode() {
		await GUESTPOST('/services/uaa/api/account/reset-password/init', user, { loader: true })
		setUser({ ownerLogin: "" })
		setShowActivationCode(!showActivationCode)
	}

	async function changePassword() {
		const response = await GUESTPOST('/services/uaa/api/account/reset-password/finish', user, { loader: true })
		if (response) {
			toast.success(t('SUCCESS') + ': ' + t('DATA_SUCCESFULLY_CHANGED'))
			navigate("/auth/login")
		}
	}

	return (
		<>
			<div>
				{showActivationCode &&
					<div className="p-2">
						<div className="input-group login-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">
									<i className="uil-user"></i>
								</span>
							</div>
							<input type="text" className="form-control login-input" placeholder={t('LOGIN')}
								autoFocus name="ownerLogin" value={user.ownerLogin}
								onChange={(e) => setUser({ ...user, 'ownerLogin': e.target.value.toLowerCase()?.replace(/[^\w\s]/gi, '')?.replace(/ /g, '') })} />
						</div>

						<div className="text-center">
							<button type="button" className="login-button" onClick={() => getActivationCode()}>
								{t('GET_SMS_CODE')}
							</button>
						</div>
					</div>
				}

				{!showActivationCode &&
					<div className="p-2">
						<div className="input-group login-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">
									<i className="uil-user"></i>
								</span>
							</div>
							<input type="text" className="form-control login-input" placeholder={t('ACTIVATION_CODE')}
								autoFocus name="key" value={user.key} autoComplete="off"
								onChange={(e) => setUser({ ...user, 'key': e.target.value })} />
						</div>

						<div className="input-group login-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">
									<i className="uil-lock-alt"></i>
								</span>
							</div>
							<input type={showPassword ? "text" : "password"} className="form-control login-input" placeholder={t('NEW_PASSWORD')}
								autoFocus name="newPassword" value={user.newPassword} autoComplete="off"
								onChange={(e) => setUser({ ...user, 'newPassword': e.target.value })} />

							<div className="input-group-append">
								<span className="input-group-text second cursor" onClick={() => setShowPassword(!showPassword)}>
									{showPassword ?
										<i className="uil-eye"></i>
										:
										<i className="uil-eye-slash"></i>
									}
								</span>
							</div>
						</div>

						<div className="input-group login-group mb-3">
							<div className="input-group-prepend">
								<span className="input-group-text">
									<i className="uil-lock-alt"></i>
								</span>
							</div>
							<input type={showPassword2 ? "text" : "password"} className="form-control login-input" placeholder={t('CONFIRM_PASSWORD')}
								autoFocus name="repeatPassword" value={user.repeatPassword} autoComplete="off"
								onChange={(e) => setUser({ ...user, 'repeatPassword': e.target.value })} />
							<div className="input-group-append">
								<span className="input-group-text second cursor" onClick={() => setShowPassword2(!showPassword2)}>
									{showPassword2 ?
										<i className="uil-eye"></i>
										:
										<i className="uil-eye-slash"></i>
									}
								</span>
							</div>
						</div>

						{/* <input className="form-control login-input"
							autoComplete="off"
							name="key"
							value={user.key}
							placeholder={t('ACTIVATION_CODE')}
							onChange={(e) => setUser({ ...user, 'key': e.target.value })} />

						<input className="form-control login-input"
							autoComplete="off"
							name="newPassword"
							value={user.newPassword}
							placeholder={t('NEW_PASSWORD')}
							onChange={(e) => setUser({ ...user, 'newPassword': e.target.value })} />

						<input className="form-control login-input"
							autoComplete="off"
							name="repeatPassword"
							value={user.repeatPassword}
							placeholder={t('CONFIRM_PASSWORD')}
							onChange={(e) => setUser({ ...user, 'repeatPassword': e.target.value })} /> */}

						<div className="text-center">
							<button type="button" className="login-button"
								onClick={changePassword}
							>
								{t('CHANGE_PASSWORD')}
							</button>
						</div>
					</div>
				}
			</div>
		</>
	)
}

export default ForgotPassword
