import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import XLSX from 'xlsx'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import Rightbar from '../cheques/Rightbar'
import { PGET, GET, POST } from 'helpers/api'
import { formatDateWithTime, formatMoney } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const reduxReport = useSelector(state => state.report)

	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 2, 'name': t('TERMINAL') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])
	const [totalAmount, setTotalAmont] = useState([])
	const [modalPayment, setModalPayment] = useState(false)
	const [paymentData, setPaymentData] = useState({})

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.chequeId !== 0) {
			if (boolean) {
				const response = await GET("/services/wms/api/cheque-byId/" + item.chequeId, {}, { loader: true })
				dataCopy[index]['itemsList'] = response
				dataCopy[index]['showSidebar'] = boolean
				setSelectedItem(response)
				setShowRightBar(boolean)
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function payment(e) {
		e.preventDefault();

		var paymentDataCopy = { ...paymentData }

		var sendData = {}
		sendData.amountIn = paymentDataCopy.amountIn
		sendData.currencyId = paymentDataCopy.currencyId
		sendData.clientId = reduxReport.reportSelectedClient.clientId
		sendData.paymentTypeId = paymentDataCopy.paymentTypeId
		sendData.posId = paymentDataCopy.posId
		sendData.note = paymentDataCopy.note

		var response = await POST('/services/wms/api/clients-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	async function showPaymentModal(bool) {
		var paymentDataCopy = {}
		if (!bool) {
			paymentDataCopy.paymentTypeId = 1
			paymentDataCopy.amountTypeId = 1
			paymentDataCopy.currencyId = 1
			paymentDataCopy.amountIn = ''
		} else {
			const response = await GET('/services/wms/api/wms-helper')
			if (response) {
				setPoses(response)
			}

			paymentDataCopy.posId = response[0]['id']
			paymentDataCopy.paymentTypeId = 1
			paymentDataCopy.amountTypeId = 1
			paymentDataCopy.currencyId = 1
			paymentDataCopy.amountIn = ''
			setPaymentData(paymentDataCopy)
		}

		setModalPayment(bool)
	}

	async function getData() {
		const response = await PGET("/services/wms/api/report-client-history-pageList/" + reduxReport.reportSelectedClient.clientId, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}

		const response2 = await GET("/services/wms/api/report-client-history-total/" + reduxReport.reportSelectedClient.clientId, {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	async function exportToExcel() {
		const response = await GET('/services/wms/api/report-client-history-excel/' + reduxReport.reportSelectedClient.clientId)

		var excelHeaders = [[t('POS'), t('CHECK_NUMBER'), t('DATE'), t('STATUS'), t('PAYMENT_METHOD'), t('CURRENCY'), t('NOTE'), t('AMOUNT_OF_DEBT'), t('PAID')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].posName)
			excelItems.push(response[i].chequeNumber)
			excelItems.push(formatDateWithTime(response[i].createdDate))
			excelItems.push(response[i].paymentPurposeName)
			excelItems.push(response[i].paymentTypeName)
			excelItems.push(response[i].currencyName)
			excelItems.push(response[i].note)
			excelItems.push(formatMoney(response[i].amountOut))
			excelItems.push(formatMoney(response[i].amountIn))
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, `${t('CLIENT')}.xlsx`);
	}

	async function paginate(data) {
		const response = await PGET("/services/wms/api/report-client-history-pageList/" + reduxReport.reportSelectedClient.clientId, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<Search url={"/services/wms/api/report-client-history-pageList/" + reduxReport.reportSelectedClient.clientId} search={search} getData={getData}></Search>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true)}>
									{t('PAY')}
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={exportToExcel}>
									EXCEL
								</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))}
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('CHECK_NUMBER')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t("PAYMENT_METHOD")}</th>
									<th className="text-center">{t('CURRENCY')}</th>
									<th className="text-center">{t('NOTE')}</th>
									<th className="text-center">{t('AMOUNT_OF_DEBT')}</th>
									<th className="text-end">{t('PAID')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} onClick={() => toggleRightbar(true, item, index)} className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')}>
										<td>{item.rowNum}. {item.posName}</td>
										<td className="text-center">{item.chequeNumber}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="text-center">{item.paymentPurposeName}</td>
										<td className="text-center">{item.paymentTypeName}</td>
										<td className="text-center">{item.currencyName}</td>
										<td className="text-center">{item.note}</td>
										<td className="text-center">{formatMoney(item.amountOut)}</td>
										<td className="text-end">{formatMoney(item.amountIn)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}</label>
							<Select
								options={poses}
								value={poses.find(option => option.id === paymentData.posId)}
								onChange={(option) => setPaymentData({ ...paymentData, 'posId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === paymentData.paymentTypeId)}
								onChange={(option) => setPaymentData({ ...paymentData, 'paymentTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={currencies}
								value={currencies.find(option => option.id === paymentData.currencyId)}
								onChange={(option) => setPaymentData({ ...paymentData, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountIn" autoFocus
								onChange={(e) => setPaymentData({ ...paymentData, 'amountIn': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setPaymentData({ ...paymentData, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100" disabled={!paymentData.amountIn}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
