import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import Rightbar from '../cheques/Rightbar'
import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET, POST, PUT } from 'helpers/api'
import { formatDate, formatDateWithTime, formatMoney, formatDateBackend } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const settings = useSelector(state => state.settings)
	const reduxReport = useSelector(state => state.report)

	const URL = "/services/wms/api/report-organization-history-pageList/" + reduxReport.reportSelectedOrganization.organizationId
	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 2, 'name': t('TERMINAL') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const amountTypes = [{ 'id': 2, 'name': t('OUT_BALANCE') }, { 'id': 1, 'name': t('IN_BALANCE') }]
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [showRightBar, setShowRightBar] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [totalAmount, setTotalAmont] = useState([])
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [paymentPoses, setPaymentPoses] = useState([])

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.chequeId !== 0) {
			if (boolean) {
				const response = await GET("/services/wms/api/cheque-byId/" + item.chequeId, {}, { loader: true })
				dataCopy[index]['itemsList'] = response
				dataCopy[index]['showSidebar'] = boolean
				setSelectedItem(response)
				setShowRightBar(boolean)
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function getData() {
		var filter = ''
		if (reduxReport.reportSelectedOrganization?.wmsId) {
			filter = `?wmsId=${reduxReport.reportSelectedOrganization.wmsId}`
		} else {
			filter = ''
		}

		const response = await PGET(URL + filter, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}

		const response2 = await GET(`/services/wms/api/report-organization-history-total/${reduxReport.reportSelectedOrganization.organizationId}` + filter, {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	async function exportToExcel() {
		const response = await GET('/services/wms/api/report-organization-history-excel/' + reduxReport.reportSelectedOrganization.organizationId)

		var excelHeaders = [[t('POS'), t('ORGANIZATION'), t('PAYMENT_METHOD'), t('NOTE'), t('DATE'), t('CURRENCY'), t('IN_BALANCE'), t('OUT_BALANCE')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].wmsName)
			excelItems.push(response[i].organizationName)
			excelItems.push(response[i].paymentTypeName)
			excelItems.push(response[i].note)
			excelItems.push(formatDateWithTime(response[i].createdDate))
			excelItems.push(response[i].currencyName)
			excelItems.push(formatMoney(response[i].amountIn))
			excelItems.push(formatMoney(response[i].amountOut))
			excelHeaders.push(excelItems)
		}

		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, `${t('SUPPLIER')}(${response[0].organizationName}).xlsx`);
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		if (selectedItemCopy.amountTypeId === 2) {
			selectedItemCopy.amountOut = selectedItemCopy.amountIn
			selectedItemCopy.amountIn = 0
		}

		var sendData = {}
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.amountOut = selectedItemCopy.amountOut
		sendData.currencyId = selectedItemCopy.currencyId
		sendData.organizationId = selectedItemCopy.organizationId
		sendData.paymentTypeId = selectedItemCopy.paymentTypeId
		sendData.wmsId = selectedItemCopy.wmsId
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/wms/api/organization-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	function showPaymentModal(bool) {
		var selectedItemCopy = { ...reduxReport.reportSelectedOrganization }
		if (!bool) {
			selectedItemCopy.paymentTypeId = 1
			selectedItemCopy.amountTypeId = 2
			selectedItemCopy.currencyId = 1
			selectedItemCopy.amountIn = ''
			selectedItemCopy.amountOut = 0
		} else {
			selectedItemCopy.paymentTypeId = 1
			selectedItemCopy.amountTypeId = 1
			selectedItemCopy.currencyId = 1
			selectedItemCopy.amountIn = ''
			selectedItemCopy.amountOut = 0
			setSelectedItem(selectedItemCopy)
		}

		setModalPayment(bool)
	}

	function handleRowChange(date, index, type) {
		var dataCopy = [...data]

		if (type === 'show') {
			dataCopy[index]['show'] = !dataCopy[index]['show']
		}
		if (type === 'date') {
			dataCopy[index]['returnDate'] = formatDateBackend(date)
		}
		setData(dataCopy)
	}

	async function updateReturnDate(index) {
		await PUT('/services/wms/api/organization-date', {
			'id': data[index]['id'],
			'returnDate': formatDateBackend(data[index]['returnDate'])
		}, { loader: true })
		getData()
	}

	async function paginate(data) {
		var filter = ''
		if (reduxReport.reportSelectedOrganization.wmsId) {
			filter = `?wmsId=${reduxReport.reportSelectedOrganization.wmsId}`
		}
		const response = await PGET(URL + filter, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	async function getPoses() {
		const response = await GET('/services/wms/api/wms-helper')
		if (response) {
			setPaymentPoses(response)
		}
	}

	function redirectPreview(id, type) {
		if (type === "in") {
			navigate(`/wms/documents-in/preview/${id}`)
		}
		if (type === "out") {
			navigate(`/wms/documents-out/preview/${id}`)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<Search url={URL} search={search} getData={getData}></Search>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={() => showPaymentModal(true)}>
									{t('PAY')}
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={exportToExcel}>
									EXCEL
								</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))}
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('WAREHOUSE')}</th>
									<th className="text-center">{t("PAYMENT_METHOD")}</th>
									<th className="text-center">{t("NOTE")}</th>
									<th className="text-center">{t("DATE")}</th>
									<th className="text-center">{t("DEBT_PAYMENT_DATE")}</th>
									<th className="text-center">{t("CURRENCY")}</th>
									<th className="text-center">{t("IN_BALANCE")}</th>
									<th className="text-center">{t("OUT_BALANCE")}</th>
									<th className="text-center">{t("ACTION")}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} onClick={() => toggleRightbar(true, item, index)}>
										<td>{item.wmsName}</td>
										<td className="text-center">{item.paymentTypeName}</td>
										<td className="text-center">{item.note}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="text-center cursor">
											{!item.show &&
												<span>
													{formatDate(item.returnDate)}
												</span>
											}
											{item.show &&
												<div className="d-flex">
													<DatePicker
														className="auto-width-input"
														dateFormat="dd.MM.yyyy"
														selected={item.returnDate ? new Date(item.returnDate) : ''}
														onChange={(date) => handleRowChange(date, index, 'date')} />
												</div>
											}
										</td>
										<td className="text-center">{item.currencyName}</td>
										<td className="text-center">{formatMoney(item.amountIn)}</td>
										<td className="text-center">{formatMoney(item.amountOut)}</td>
										<td>
											<div className="table-actions end">
												{item.show &&
													<>
														<div className="table-action-button table-action-primary-button"
															onClick={() => updateReturnDate(index)}>
															<i className="uil-check"></i>
														</div>
														<div className="table-action-button table-action-danger-button"
															onClick={() => handleRowChange(item.show, index, 'show')}>
															<i className="uil-times"></i>
														</div>
													</>
												}
												{!item.show &&
													<>
														{item.amountIn > 0 &&
															<div className="table-action-button table-action-primary-button"
																onClick={() => handleRowChange(item.show, index, 'show')}>
																<i className="uil-edit"></i>
															</div>
														}

														<div className="table-action-button table-action-primary-button"
															onClick={() => redirectPreview(item.documentsInId, "in")}>
															<i className="uil-bring-front"></i>
														</div>
														<div className="table-action-button table-action-primary-button"
															onClick={() => redirectPreview(item.documentsOutId, "out")}>
															<i className="uil-backspace"></i>
														</div>
													</>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('ORGANIZATION')}</span>
						<span>{selectedItem.organizationName}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}</label>
							<Select
								options={paymentPoses}
								value={paymentPoses.find(option => option.id === selectedItem.wmsId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'wmsId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={currencies}
								value={currencies.find(option => option.id === selectedItem.currencyId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={amountTypes}
								value={amountTypes.find(option => option.id === selectedItem.amountTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'amountTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amountIn" autoFocus
								value={selectedItem.amountIn ? formatMoney(selectedItem.amountIn, 0, 0) : ''}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100" disabled={!selectedItem.amountIn && !selectedItem.amountOut}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
