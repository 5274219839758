import React, { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import { Form } from 'react-bootstrap'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { GET, POST, PUT, FILE } from 'helpers/api'
import { toast } from "react-toastify"

function CreateUpdate() {
	const { id } = useParams();
	const { t } = useTranslation()
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const seasons = [{ id: 0, name: t('EMPTY') }, { id: 1, name: t('SEASONAL') }, { id: 2, name: t('NOT_SEASONAL') }];

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			imageUrl: '',
			name: '',
			barcode: '',
			brandName: '',
			manufacturerName: '',
			unitList: [],
			uomId: 1,
			categoryId: 2,
			seasonal: 0,
			productBox: false,
			scales: false,
			documentIn: true,
		}
	});
	const { errors } = formState

	const [imageUrl, setImageUrl] = useState('');

	const [categories, setCategories] = useState([])
	const [packages, setPackages] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [uoms, setUoms] = useState([])

	const [data, setData] = useState({
		imageUrl: '',
		name: '',
		barcode: '',
		brandName: '',
		manufacturerName: '',
		unitList: [],
		uomId: 1,
		categoryId: 2,
		seasonal: 0,
		productBox: false,
		scales: false,
		documentIn: true,
		convert: false,
		secondUomId: '',
		secondQuantity: '',
	})
	const [unit, setUnit] = useState({ name: '', quantity: '' })
	const [showModifications, setShowModifications] = useState(false)

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/web/api/upload/image', formData, {}, { loader: true });
		setImageUrl(response.url)
		setValue('imageUrl', response.url)
	}

	async function createUpdate(validatedData) {
		if (reduxSettings.ofd) {
			if (validatedData.gtin.length === 0) {
				return toast.error('ИКПУ ' + t('required'))
			}
			if (validatedData.gtin.split('').every(char => char === validatedData.gtin[0])) {
				return toast.error('ИКПУ ' + t('ERROR'))
			}
		}
		var sendData = { ...validatedData }
		sendData.unitList = data.unitList
		sendData.packageCode = data.packageCode
		sendData.packageName = data.packageName
		sendData.packages = packages
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/product', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/product', sendData, { loader: true })
		}

		if (response) {
			navigate('/products')
		}
	}

	function addUnit() {
		var dataCopy = { ...data }
		if (dataCopy.unitList.length === 0) {
			dataCopy.unitList.push({ ...unit })
			setUnit({ name: "", quantity: "" })
		} else {
			var unitFound = dataCopy.unitList.find(x => x.name === unit.name)
			if (!unitFound) {
				dataCopy.unitList.push({ ...unit })
				setUnit({ name: "", quantity: "" })
			}
		}
	}

	function deleteUnit(index) {
		var dataCopy = { ...data }
		dataCopy.unitList.splice(index, 1)
		setData(dataCopy)
	}

	function generateRandomBarcode() {
		setValue('barcode', Math.floor(Math.random() * 899999 + 100000))
	}

	async function searchFromDatabase() {
		const response = await GET('/services/web/api/product-db-barcode?barcode=' + getValues('barcode'), {})
		if (response) {
			setValue('name', response.name)
			setValue('brandName', response.brandName)
			setValue('manufacturerName', response.manufacturerName)
			setValue('categoryId', response.categoryId)
			setValue('uomId', response.uomId)
			setValue('imageUrl', response.imageUrl)
			setImageUrl(response.imageUrl)
		}
	}

	async function searchOfdProduct(type = 'barcode') {
		var params
		if (type === 'barcode') {
			params = `?barcode=${getValues('barcode')}`
		}
		if (type === 'gtin') {
			params = `?gtin=${getValues('gtin')}`
		}

		const response = await GET(`/services/web/api/get-ofd-product${params}`, {}, { loader: true })
		if (response && response.gtin) {
			setValue('name', response.name)
			setValue('gtin', response.gtin)
			setPackages(response.packages)
		} else {
			return toast.error('ИКПУ ' + t('NOT_FOUND'))
		}
	}

	function selectCategory(selectedOption) {
		var copyCategories = [...categories]
		var index = copyCategories.findIndex(item => item.id === selectedOption.id)
		for (let i = 0; i < selectedOption?.options?.length; i++) {
			selectedOption.options[i].show = !selectedOption.options[i].show;
		}
		copyCategories[index] = selectedOption
		setCategories(copyCategories)
	}

	async function getData() {
		const response = await GET('/services/web/api/product/' + id, {}, { loader: true })
		if (response?.packages?.length) {
			setPackages(response?.packages)
		}
		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}

		setImageUrl(response.imageUrl)
		if (response.unitList?.length > 0) {
			setShowModifications(true)
		}

		setData({
			...data,
			'unitList': response.unitList,
			'packageCode': response.packageCode,
			'packageName': response.packageName,
			'convert': response.convert,
		})
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				response[i].show = false;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getUoms() {
		const response = await GET('/services/web/api/product-uom-helper')
		setUoms(response)
	}

	useEffect(() => {
		if (id) {
			getData()
		} else {
			if (reduxSettings.categoryId) {
				setValue('categoryId', reduxSettings.categoryId)
			}
		}
		getCategories()
		getUoms()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const Option = props => {
		return (
			props.data.show ?
				<div style={{
					padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
					fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
				}}>
					<components.Option {...props} />
				</div>
				:
				<></>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: '#999',
				fontWeight: 'bold',
				backgroundColor: '#fff',
				fontSize: '.8rem'
			}}
				onClick={() => selectCategory(props.data)}>
				<div className="d-flex align-items-center">
					<components.Option {...props} />
					<i className="uil uil-angle-down fz-30" />
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="row">
							<div className="col-md-3 mt-3">
								<div className="con-upload">
									<div className="con-img-upload">
										{imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file" onClick={() => setImageUrl('')}>
													<i className="uil-times"></i>
												</button>
												<img src={process.env.REACT_APP_PROD_SERVER_URL + imageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t("NAME_OF_PRODUCT")}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" tabIndex="1" name="name" {...register('name')} autoFocus />
									<span className="text-danger text-sm">{errors.name?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('BARCODE')}</label>
									<div className="position-relative">
										<input type="number" className="form-control" tabIndex="2" name="barcode" disabled={id} {...register('barcode')} />
										<span className="text-danger text-sm">{errors.barcode?.message}</span>
										<i className="uil-sync input-right-icon"
											style={{ right: '55px' }}
											onClick={() => generateRandomBarcode()}></i>
										<i className="uil-qrcode-scan input-right-icon" onClick={searchFromDatabase}
											style={{ right: '30px' }}></i>
										<i className="uil uil-shield-check input-right-icon" onClick={() => searchOfdProduct('barcode')}></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t("ARTICLE")}</label>
									<input type="text" className="form-control" name="name" {...register('artikul')} />
								</div>
								<div className="form-group">
									<label>{t('delivery_days')}</label>
									<input type="number" className="form-control" name="deliveryDay" {...register('deliveryDay')} />
									<span className="text-danger text-sm">{errors.deliveryDay?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('SEASONALITY')}</label>
									<Controller
										name="seasonal"
										control={control}
										render={({ field }) =>
											<Select
												//isDisabled={id}
												tabIndex={3}
												{...field}
												options={seasons}
												value={seasons.find(option => option.id === getValues('seasonal'))}
												onChange={(e) => setValue('seasonal', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
									<span className="text-danger text-sm">{errors.seasonal?.message}</span>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('CATEGORY')}<span className="required-mark">*</span></label>
									<Controller
										name="categoryId"
										control={control}
										render={({ field }) =>
											<Select
												{...field}
												components={{ GroupHeading: GroupHead, Option, }}
												classNamePrefix="react-select"
												options={categories}
												value={categoriesInitial.find(option => option.id === getValues('categoryId'))}
												onChange={(e) => setValue('categoryId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
											/>
										}
									/>
								</div>
								<div className="form-group">
									<label>{t('UNIT_OF_MEASUREMENT')}<span className="required-mark">*</span></label>
									<Controller
										name="uomId"
										control={control}
										render={({ field }) =>
											<Select
												//isDisabled={id}
												tabIndex={3}
												{...field}
												options={uoms}
												value={uoms.find(option => option.id === getValues('uomId'))}
												onChange={(e) => setValue('uomId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										}
									/>
								</div>
								<div className="form-group">
									<label>{t("COMPLETED")}%</label>
									<input type="number" className="form-control" name="complete" {...register('complete')} />
								</div>
								<div className="form-group">
									<label>{t('ADDITIONAL_UNIT_OF_MEASUREMENT')}</label>
									<Controller
										name="modUomId"
										control={control}
										render={({ field }) =>
											<Select
												//isDisabled={id}
												tabIndex={3}
												{...field}
												options={uoms}
												value={uoms.find(option => option.id === getValues('modUomId'))}
												onChange={(e) => setValue('modUomId', e.id)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>}
									/>
									<span className="text-danger text-sm">{errors.modUomId?.message}</span>
								</div>
								{console.log(data)}
								<div className="form-group">
									<label>OFD {t('UNIT_OF_MEASUREMENT')}<span className="required-mark">*</span></label>
									<Select
										tabIndex={3}
										options={packages}
										value={packages.find(option => option.id === data.packageCode)}
										onChange={(option) => setData({ ...data, 'packageCode': option.id, 'packageName': option.name })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('MANUFACTURER')}</label>
									<input type="text" className="form-control" name="manufacturerName" {...register('manufacturerName')} />
									<span className="text-danger text-sm">{errors.manufacturerName?.message}</span>
								</div>
								<div className="form-group">
									<label>{t('TRADEMARK')}</label>
									<input type="text" className="form-control" name="brandName" {...register('brandName')} />
									<span className="text-danger text-sm">{errors.brandName?.message}</span>
								</div>

								<div className="form-group">
									<label>ИКПУ</label>
									<div className="position-relative">
										<input type="number" className="form-control" name="gtin" {...register('gtin')} />
										<span className="text-danger text-sm">{errors.gtin?.message}</span>
										<i className="uil uil-shield-check input-right-icon" onClick={() => searchOfdProduct('gtin')}></i>
									</div>
								</div>
								<div className="form-group">
									<label>{t('ADDITIONAL_QUANTITY')}</label>
									<input type="number" className="form-control" name="modQuantity" {...register('modQuantity')} />
									<span className="text-danger text-sm">{errors.modQuantity?.message}</span>
								</div>
								<div className="d-flex gap-3">
									<div className="form-group">
										<label>{t("MARKING")}</label>
										<Form.Check // prettier-ignore
											type="checkbox"
											{...register('marking')}
										/>
									</div>
									<div className="form-group">
										<label>{t("DOCUMENT_IN")}</label>
										<Form.Check // prettier-ignore
											type="checkbox"
											{...register('documentIn')}
										/>
									</div>
									<div className="form-group">
										<label>{t("PACKAGE")}</label>
										<Form.Check // prettier-ignore
											type="checkbox"
											{...register('productBox')}
											onChange={(e) => setData({ ...data, productBox: e.target.checked })}
										/>
									</div>
									<div className="form-group">
										<label>{t("SCALE")}</label>
										<Form.Check // prettier-ignore
											type="checkbox"
											{...register('scales')}
											onChange={(e) => setData({ ...data, scales: e.target.checked })}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">

							<div className="col-md-6">
								<div className="input-borer-only p-3 my-3">
									<div className="d-flex">
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input"
												checked={showModifications}
												onChange={() => setShowModifications(!showModifications)} />
										</div>
										<h4><strong>{t('UNIT_OF_MEASUREMENT')}</strong></h4>
									</div>

									<hr />
									{showModifications &&
										<div className={data.productBox ? "col-md-12" : "col-md-6"}>
											<div className="table-responsive">
												<table className="table table-striped mb-0">
													<thead>
														<tr>
															<th>{t('MEASUREMENT_DESCRIPTION')}</th>
															<th>{t('NUMBER_OF_UNITS')}</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<input type="text" className="form-control" value={unit.name}
																	onChange={(e) => setUnit({ ...unit, 'name': e.target.value })} />
															</td>
															<td>
																<input type="number" className="form-control" value={unit.quantity}
																	onChange={(e) => setUnit({ ...unit, 'quantity': e.target.value })} />
															</td>
															<td>
																<button type="button" className="btn btn-outline-primary w-100"
																	disabled={!unit.name || !unit.quantity}
																	onClick={() => addUnit()}>
																	{t('ADD')}
																</button>
															</td>
														</tr>
														{data.unitList?.map((item, index) => (
															<tr key={index}>
																<td>{item.name}</td>
																<td>{item.quantity}</td>
																<td onClick={() => deleteUnit(index)}>
																	<div className="table-actions">
																		<div className="table-action-button table-action-danger-button">
																			<i className="uil uil-trash-alt"></i>
																		</div>
																	</div>
																</td>
															</tr>
														))
														}
													</tbody>
												</table>
											</div>
										</div>
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className="input-borer-only p-3 my-3">
									<div className="d-flex">
										<div className="form-check form-switch form-switch-lg">
											<input type="checkbox" className="form-check-input"
												{...register('convert')}
												onChange={(e) => setData({ ...data, convert: e.target.checked })} />
										</div>
										<h4><strong>{t('CONVERT')}</strong></h4>
									</div>

									<hr />
									{data.convert &&
										<div className="d-flex gap-3">
											<div className="form-group w-100">
												<label>{t("MEASUREMENT_DESCRIPTION")}</label>
												<Controller
													name="secondUomId"
													control={control}
													render={({ field }) =>
														<Select
															options={uoms}
															value={uoms.find(option => option.id === getValues('secondUomId'))}
															onChange={(e) => setValue('secondUomId', e.id)}
															placeholder=""
															noOptionsMessage={() => t('LIST_IS_EMPTY')}
															getOptionLabel={(option) => option.name}
															getOptionValue={(option) => option.id}
														/>
													}
												/>
											</div>
											<div className="form-group w-100">
												<label>{t("QUANTITY")}</label>
												<input type="number" className="form-control" {...register('secondQuantity')} />
											</div>

										</div>
									}
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link className="btn btn-outline-danger btn-wide me-2" to="/products">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-outline-primary btn-wide btn-wide" tabIndex="4" onClick={handleSubmit(createUpdate)}>{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate
