
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select, { components } from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch';
import { Dropdown } from 'react-bootstrap';

import useDidMountEffect from 'pages/parts/useDidMountEffect';

import { PGET, GET, DOWNLOAD_FILE } from 'helpers/api';
import { findFromArrayById } from 'helpers/helpers';
import Pagination from 'pages/parts/Pagination';

function ProductAction() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxFilterData = useSelector(state => state.filterData)
	const reduxSettings = useSelector(state => state.settings)

	const URL = `/services/web/api/analytics-products-action`

	const [pageCount, setPageCount] = useState(0)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [data, setData] = useState([])

	const [filterData, setFilterData] = useState({
		search: '',
		productCategoryId: '',
		page: 0,
		random: 0,
	})

	async function exportToExcel() {
		await DOWNLOAD_FILE(`/services/web/api/analytics-products-action-excel`, t('PRODUCT_ACTION'), filterData)
	}

	function resetFilter() {
		setFilterData({
			search: '',
			productCategoryId: '',
			page: 0,
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.search === '' &&
			filterData.productCategoryId === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL, filterData, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { ...filterData, page: data.selected, size: reduxSettings.size }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
		setData(response.data);
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	useEffect(() => {
		if (reduxFilterData?.productAction) {
			setFilterData({ ...filterData, ...reduxFilterData?.productAction, random: filterData.random + 1 })
		} else {
			getData(true)
		}
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'productAction', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	async function selectCategory(option) {
		setIsOpen(false)
		if (option === null) {
			setFilterData({ ...filterData, 'productCategoryId': null })
			return
		}
		setFilterData({ ...filterData, 'productCategoryId': option.id })
	}

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.productCategoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.productCategoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={(e) => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}
								reduxFilterSearch={true}
							/>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('CATEGORY')}</label>
											<Select
												isClearable
												components={{ GroupHeading: GroupHead, Option }}
												classNamePrefix="react-select"
												options={categories}
												value={categoriesInitial.find(option => option.id === filterData.productCategoryId) || ''}
												onChange={(option) => selectCategory(option)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
												onMenuOpen={() => setIsOpen(true)}
												onBlur={() => setIsOpen(false)}
												menuIsOpen={isOpen}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-outline-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
													{t('APPLY')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => exportToExcel()}>EXCEL</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categoriesInitial, filterData.productCategoryId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th className="text-center">{t('NAME_OF_PRODUCT')}</th>
									<th className="text-end">{t('BARCODE')}</th>
									<th className="text-end">{t('ARTIKUL')}</th>
									<th className="text-end">{t('IN_BALANCE')}</th>
									<th className="text-end">{t('RETURN')}</th>
									<th className="text-end">{t('WRITE_OFF')}</th>
									<th className="text-end">{t('INVENTORY')}</th>
									<th className="text-end">{t('SALE')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}.</td>
										<td>{item.productName.substring(0, 25)}</td>
										<td className="text-end">{item.productBarcode}</td>
										<td className="text-end">{item.productArtikul}</td>
										<td className="text-end">{item.maxDocumentInDate}</td>
										<td className="text-end">{item.maxDocumentOutDate}</td>
										<td className="text-end">{item.maxWriteOffDate}</td>
										<td className="text-end">{item.maxInventoryDate}</td>
										<td className="text-end">{item.maxChequeDate}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>
		</>
	)
}

export default ProductAction