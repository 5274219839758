import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactApexChart from 'react-apexcharts'
import Select from 'react-select'

import { GET } from 'helpers/api'
import { formatMoney } from 'helpers/helpers';
import useDidMountEffect from 'pages/parts/useDidMountEffect'


function Index() {
	const { t } = useTranslation()

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [currentType, setCurrentType] = useState('totalAmount')
	const [posId, setPosId] = useState('')
	const [currencyId, setCurrencyId] = useState(1)

	const [poses, setPoses] = useState([])

	const [data, setData] = useState({})
	const [sales, setSales] = useState({})
	const [topProducts, setTopProducts] = useState({})

	const [chartData, setChartData] = useState({
		series: [
			{
				name: "",
				data: []
			},
			{
				name: "",
				data: []
			}
		],

		options: {
			colors: ['#5b73e8', '#ccd4ff'],
			chart: {
				height: 350,
				type: 'line',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},
			markers: {
				size: 5,
				colors: undefined,
				strokeWidth: 0,
				shape: "circle",
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				tooltip: {
					enabled: false
				},
				categories: [],
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	})


	const [pieChartData, setPieChartData] = useState({
		topProductsAmount: [],
		topProductsChequeCount: [],
		topProductsIncome: [],
		topProductsQuantity: [],

		showTopProductsAmount: 1,
		showTopProductsChequeCount: 1,
		showTopProductsIncome: 1,
		showTopProductsQuantity: 1,

		options: {
			chart: {
				width: 380,
				type: 'pie',
			},
			topProductsAmountLabel: [],
			topProductsChequeCountLabel: [],
			topProductsIncomeLabel: [],
			topProductsQuantityLabel: [],
			legend: {
				show: false,
			},
			stroke: {
				width: 0
			},
			tooltip: {
				x: {
					show: false,
				},
				y: {
					formatter: function (value) {
						return value + '%'
					},
				},
			},
		},
	})

	const [topLoader, setTopLoader] = useState(0)
	const [dashboardLoader, setDashboardLoader] = useState(0)
	const [topProductsloader, setTopProductsloader] = useState(0)

	function changeChartData(type) {
		const salesCopy = [...sales]
		var series = [
			{
				name: t('CURRENT_MONTH'),
				data: [],
			},
			{
				name: t('LAST_MONTH'),
				data: [],
			},
		]
		for (let i = 0; i < salesCopy.length; i++) {
			series[0].data.push(salesCopy[i][type])
			series[1].data.push(salesCopy[i][type + 'Previous'])
		}
		setChartData({ ...chartData, series: series })
		setCurrentType(type)
	}

	async function getData() {
		setTopLoader(1)
		const dashboard = await GET(`/services/web/api/dashboard/${posId}/${currencyId}`)
		if (dashboard) {
			setData(dashboard)

			setTopLoader(2)
		} else {
			setTopLoader(0)
		}
	}

	async function getDashboardSales() {
		setDashboardLoader(1)
		const dashboardSales = await GET(`/services/web/api/dashboard-sales/${posId}/${currencyId}`)
		if (dashboardSales && dashboardSales.length > 0) {
			var xaxis = {
				categories: [],
			}
			var data = []
			var dataSecond = []

			for (let i = 0; i < dashboardSales.length; i++) {
				xaxis.categories.push(dashboardSales[i]['chequeDate'])
				data.push(dashboardSales[i]['totalAmount'])
				dataSecond.push(dashboardSales[i]['totalAmountPrevious'])
			}

			setChartData({
				...chartData,
				options: { ...chartData.options, xaxis: xaxis },
				series: [
					{
						name: t('CURRENT_MONTH'),
						data: data
					},
					{
						name: t('LAST_MONTH'),
						data: dataSecond
					}
				]
			})
			setSales(dashboardSales)
			setDashboardLoader(2)
		} else {
			setDashboardLoader(0)
		}
	}

	async function getDashboardTopProducts() {
		setTopProductsloader(1)
		const dashboardTopProducts = await GET(`/services/web/api/dashboard-top-products/${posId}/${currencyId}`)
		if (dashboardTopProducts) {
			setTopProducts(dashboardTopProducts)
			var pieChartDataCopy = { ...pieChartData }
			for (let i = 0; i < dashboardTopProducts?.topProductsAmount?.length; i++) {
				pieChartDataCopy.topProductsAmount.push(dashboardTopProducts?.topProductsAmount[i]?.percent)
				pieChartDataCopy.options.topProductsAmountLabel.push(dashboardTopProducts?.topProductsAmount[i]?.productName)
			}
			for (let i = 0; i < dashboardTopProducts?.topProductsChequeCount?.length; i++) {
				pieChartDataCopy.topProductsChequeCount.push(dashboardTopProducts?.topProductsChequeCount[i]?.percent)
				pieChartDataCopy.options.topProductsChequeCountLabel.push(dashboardTopProducts?.topProductsChequeCount[i]?.productName)
			}
			for (let i = 0; i < dashboardTopProducts?.topProductsIncome?.length; i++) {
				pieChartDataCopy.topProductsIncome.push(dashboardTopProducts?.topProductsIncome[i]?.percent)
				pieChartDataCopy.options.topProductsIncomeLabel.push(dashboardTopProducts?.topProductsIncome[i]?.productName)
			}
			for (let i = 0; i < dashboardTopProducts?.topProductsQuantity?.length; i++) {
				pieChartDataCopy.topProductsQuantity.push(dashboardTopProducts?.topProductsQuantity[i]?.percent)
				pieChartDataCopy.options.topProductsQuantityLabel.push(dashboardTopProducts?.topProductsQuantity[i]?.productName)
			}
			setPieChartData(pieChartDataCopy)
			setTopProductsloader(2)
		} else {
			setTopProductsloader(0)
		}
	}

	function changeFilter(type, value) {
		setPieChartData({
			topProductsAmount: [],
			topProductsChequeCount: [],
			topProductsIncome: [],
			topProductsQuantity: [],

			showTopProductsAmount: 1,
			showTopProductsChequeCount: 1,
			showTopProductsIncome: 1,
			showTopProductsQuantity: 1,

			options: {
				chart: {
					width: 380,
					type: 'pie',
				},
				topProductsAmountLabel: [],
				topProductsChequeCountLabel: [],
				topProductsIncomeLabel: [],
				topProductsQuantityLabel: [],
				legend: {
					position: 'bottom'
				},
				stroke: {
					width: 0
				},
			},
		})

		if (type === 'posId') {
			setPosId(value)
		} else if (type === 'currencyId') {
			setCurrencyId(value)
		}
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			setPoses(response)
			setPosId(response[0]?.id)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
		getDashboardSales()
		getDashboardTopProducts()
	}, [posId, currencyId]);

	return (
		<>

			<div className="d-flex align-items-center mb-3 gap-3">
				<Select
					options={poses}
					value={poses.find(option => option.id === posId) || ''}
					onChange={(option) => changeFilter('posId', option.id)}
					placeholder=""
					noOptionsMessage={() => t('LIST_IS_EMPTY')}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					className="w-25"
				/>

				<Select
					options={currencies}
					value={currencies.find(option => option.id === currencyId) || ''}
					onChange={(option) => changeFilter('currencyId', option.id)}
					placeholder=""
					noOptionsMessage={() => t('LIST_IS_EMPTY')}
					getOptionLabel={(option) => option.name}
					getOptionValue={(option) => option.id}
					className="w-15"
				/>
			</div>


			{topLoader === 2 &&
				<div className="d-flex align-items-center gap-3 mb-3 dashboard-flex">
					<div className="dashboard-card-mobile w-100">
						<div className="card h-100">
							<div className="card-body dashboard-card">
								<div className="w-100">
									<p className="dashboard-card_text_001">{t('SALES')}</p>
									<h4 className="dashboard-card_text_002">
										{formatMoney(data.totalAmount, 0)}
										<div className={"dashboard-card_text_005 " + (data.totalAmountPercent >= 0 ? 'success' : 'danger')}
											title={t('relative_to_yesterday')}>
											<i className={data.totalAmountPercent >= 0 ? 'uil-arrow-growth' : 'uil-chart-down'}></i>
											{formatMoney(data.totalAmountPercent, 0)} %
										</div>
									</h4>
									<p className="dashboard-card_text_003">
										{t('TODAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.totalAmountToday, 0)}</span>
									</p>
									<p className="dashboard-card_text_003">
										{t('YESTERDAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.totalAmountYesterday, 0)}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-100 dashboard-card-mobile">
						<div className="card h-100">
							<div className="card-body dashboard-card">
								<div className="w-100">
									<p className="dashboard-card_text_001">{t('GROSS_INCOME')}</p>
									<h4 className="dashboard-card_text_002">
										{formatMoney(data.totalIncome, 0)}
										<div className={"dashboard-card_text_005 " + (data.totalIncomePercent >= 0 ? 'success' : 'danger')}
											title={t('relative_to_yesterday')}>
											<i className={data.totalIncomePercent >= 0 ? 'uil-arrow-growth' : 'uil-chart-down'}></i>
											{formatMoney(data.totalIncomePercent, 0)} %
										</div>
									</h4>
									<p className="dashboard-card_text_003">
										{t('TODAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.totalIncomeToday, 0)}</span>
									</p>
									<p className="dashboard-card_text_003">
										{t('YESTERDAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.totalIncomeYesterday, 0)}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-100 dashboard-card-mobile">
						<div className="card h-100">
							<div className="card-body dashboard-card">
								<div className="w-100">
									<p className="dashboard-card_text_001">{t('NUMBER_OF_CHECKS')}</p>
									<h4 className="dashboard-card_text_002">
										{formatMoney(data.chequeCount, 0)}
										<div className={"dashboard-card_text_005 " + (data.chequeCountPercent >= 0 ? 'success' : 'danger')}
											title={t('relative_to_yesterday')}>
											<i className={data.chequeCountPercent >= 0 ? 'uil-arrow-growth' : 'uil-chart-down'}></i>
											{formatMoney(data.chequeCountPercent, 0)} %
										</div>
									</h4>
									<p className="dashboard-card_text_003">
										{t('TODAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.chequeCountToday, 0)}</span>
									</p>
									<p className="dashboard-card_text_003">
										{t('YESTERDAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.chequeCountYesterday, 0)}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-100 dashboard-card-mobile">
						<div className="card h-100">
							<div className="card-body dashboard-card">
								<div className="w-100">
									<p className="dashboard-card_text_001">{t('AVG_CHEQUE_AMOUNT')}</p>
									<h4 className="dashboard-card_text_002">
										{formatMoney(data.averageCheque, 0)}
										<div className={"dashboard-card_text_005 " + (data.averageChequePercent >= 0 ? 'success' : 'danger')}
											title={t('relative_to_yesterday')}>
											<i className={data.averageChequePercent >= 0 ? 'uil-arrow-growth' : 'uil-chart-down'}></i>
											{formatMoney(data.averageChequePercent, 0)} %
										</div>
									</h4>
									<p className="dashboard-card_text_003">
										{t('TODAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.averageChequeToday, 0)}</span>
									</p>
									<p className="dashboard-card_text_003">
										{t('YESTERDAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.averageChequeYesterday, 0)}</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-100 dashboard-card-mobile">
						<div className="card h-100">
							<div className="card-body dashboard-card">
								<div className="w-100">
									<p className="dashboard-card_text_001">{t('MARGINALITY')}</p>
									<h4 className="dashboard-card_text_002">
										{formatMoney(data.margin)} %
										<div className={"dashboard-card_text_005 " + (data.marginPercent >= 0 ? 'success' : 'danger')}
											title={t('relative_to_yesterday')}>
											<i className={data.marginPercent >= 0 ? 'uil-arrow-growth' : 'uil-chart-down'}></i>
											{formatMoney(data.marginPercent, 0)} %
										</div>
									</h4>
									<p className="dashboard-card_text_003">
										{t('TODAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.marginToday)} %</span>
									</p>
									<p className="dashboard-card_text_003">
										{t('YESTERDAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.marginYesterday)} %</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="w-100 dashboard-card-mobile">
						<div className="card h-100">
							<div className="card-body dashboard-card">
								<div className="w-100">
									<p className="dashboard-card_text_001">{t('PRODUCT_QUANTITY')}</p>
									<h4 className="dashboard-card_text_002">
										{formatMoney(data.productCount, 0)}
										<div className={"dashboard-card_text_005 " + (data.productCountPercent >= 0 ? 'success' : 'danger')}
											title={t('relative_to_yesterday')}>
											<i className={data.productCountPercent >= 0 ? 'uil-arrow-growth' : 'uil-chart-down'}></i>
											{formatMoney(data.productCountPercent, 0)} %
										</div>
									</h4>
									<p className="dashboard-card_text_003">
										{t('TODAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.productCountToday, 0)}</span>
									</p>
									<p className="dashboard-card_text_003">
										{t('YESTERDAY') + ' '}
										<span className="dashboard-card_text_004">{formatMoney(data.productCountYesterday, 0)}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			{topLoader === 1 &&
				<>
					<div className="d-flex align-items-center gap-3 mb-3">
						<div className="w-100">
							<Skeleton height={128.4} />
						</div>
						<div className="w-100">
							<Skeleton height={128.4} />
						</div>
						<div className="w-100">
							<Skeleton height={128.4} />
						</div>
						<div className="w-100">
							<Skeleton height={128.4} />
						</div>
						<div className="w-100">
							<Skeleton height={128.4} />
						</div>
						<div className="w-100">
							<Skeleton height={128.4} />
						</div>
					</div>
				</>
			}

			<div className="d-flex dashboard-flex gap-3 mb-3">
				{topLoader === 2 &&
					<div className="card w-15">
						<div className="card-body">
							<h5 className="mb-3">
								{t('FORECAST')}
							</h5>

							<div className="dashboard-card-items">
								<div className="dashboard-card-item">
									<p className="dashboard-card_text_001 mb-1">{t('SALES')}</p>
									<h4 className="dashboard-card_text_002 mb-3">
										{formatMoney(data.totalAmountEndMonth, 0)}
									</h4>
								</div>

								<div className="dashboard-card-item">
									<p className="dashboard-card_text_001 mb-1">{t('GROSS_INCOME')}</p>
									<h4 className="dashboard-card_text_002 mb-3">
										{formatMoney(data.totalIncomeEndMonth, 0)}
									</h4>
								</div>

								<div className="dashboard-card-item">
									<p className="dashboard-card_text_001 mb-1">{t('AVG_CHEQUE_AMOUNT')}</p>
									<h4 className="dashboard-card_text_002 mb-3">
										{formatMoney(data.averageChequeEndMonth, 0)}
									</h4>
								</div>

								<div className="dashboard-card-item">
									<p className="dashboard-card_text_001 mb-1">{t('MARGINALITY')}</p>
									<h4 className="dashboard-card_text_002 mb-3">
										{formatMoney(data.marginEndMonth)} %
									</h4>
								</div>
							</div>
						</div>
					</div>
				}
				{topLoader === 1 &&
					<div className="w-15">
						<Skeleton height={374} />
					</div>
				}

				{dashboardLoader === 2 &&
					<div className="card w-100">
						<div className="card-body">
							<div className="d-flex justify-content-between flex-wrap">
								<div className="d-flex align-items-center flex-wrap">
									<div className={"chart-header-title " + (currentType === 'totalAmount' ? 'active' : '')}
										onClick={() => changeChartData('totalAmount', 'SALES')}>
										{t('SALES')}
									</div>
									<div className={"chart-header-title " + (currentType === 'totalIncome' ? 'active' : '')}
										onClick={() => changeChartData('totalIncome', 'GROSS_INCOME')}>
										{t('GROSS_INCOME')}</div>
									<div className={"chart-header-title " + (currentType === 'chequeCount' ? 'active' : '')}
										onClick={() => changeChartData('chequeCount', 'NUMBER_OF_CHECKS')}>
										{t('NUMBER_OF_CHECKS')}
									</div>
								</div>

								<div className="d-flex">
									<div className="dshbutton me-3">
										<span className="current"></span>
										<p>{t('CURRENT_MONTH')}</p>
									</div>
									<div className="dshbutton me-3">
										<span className="last"></span>
										<p>{t('LAST_MONTH')}</p>
									</div>
								</div>
							</div>
							<ReactApexChart options={chartData.options} series={chartData.series} type="line" height={300} />
						</div>
					</div>
				}

				{dashboardLoader === 1 &&
					<div className="w-100">
						<Skeleton height={374} />
					</div>
				}
			</div>

			{topProductsloader === 2 &&
				<div className="d-flex dashboard-flex gap-3 mb-3" style={{ minHeight: '460.57px' }}>
					<div className="card w-100">
						<div className="card-body">
							<h5>
								{t('TOP_PRODUCTS')} - {t('SALES')}
							</h5>

							<div className="tabs_icons mt-2">
								<div className={"tab " + (pieChartData.showTopProductsAmount === 1 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsAmount: 1 })}>
									<i className="uil uil-list-ul"></i>
								</div>
								<div className={"tab " + (pieChartData.showTopProductsAmount === 2 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsAmount: 2 })}>
									<i className="uil uil-chart-pie-alt"></i>
								</div>
							</div>

							{pieChartData.showTopProductsAmount === 1 ?
								<div className="table-responsive mt-3">
									<table className="table">
										<thead>
											<tr>
												<th>{t('NAME2')}</th>
												<th className="text-end">{t('AMOUNT')}</th>
											</tr>
										</thead>
										<tbody>
											{topProducts?.topProductsAmount?.map((item, index) => (
												<tr key={index}>
													<td title={item.productName}>
														{item.rowNum}.
														{item.productName.substring(0, 17)}
														{item.productName.length > 17 && '...'}
													</td>
													<td className="text-end">
														{formatMoney(item.total)} {t('SUM')}
													</td>
												</tr>
											))
											}
										</tbody>
									</table>
								</div>
								:
								<ReactApexChart options={{ ...pieChartData.options, labels: pieChartData.options.topProductsAmountLabel }} series={pieChartData.topProductsAmount} type="pie" height={350} />
							}
						</div>
					</div>

					<div className="card w-100">
						<div className="card-body">
							<h5>
								{t('TOP_PRODUCTS')} - {t('GROSS_INCOME')}
							</h5>

							<div className="tabs_icons mt-2">
								<div className={"tab " + (pieChartData.showTopProductsIncome === 1 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsIncome: 1 })}>
									<i className="uil uil-list-ul"></i>
								</div>
								<div className={"tab " + (pieChartData.showTopProductsIncome === 2 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsIncome: 2 })}>
									<i className="uil uil-chart-pie-alt"></i>
								</div>
							</div>

							{pieChartData.showTopProductsIncome === 1 ?
								<div className="table-responsive mt-3">
									<table className="table">
										<thead>
											<tr>
												<th>{t('NAME2')}</th>
												<th className="text-end">{t('AMOUNT')}</th>
											</tr>
										</thead>
										<tbody>
											{topProducts?.topProductsIncome?.map((item, index) => (
												<tr key={index}>
													<td title={item.productName}>
														{item.rowNum}.
														{item.productName.substring(0, 17)}
														{item.productName.length > 17 && '...'}
													</td>
													<td className="text-end">
														{formatMoney(item.total)} {t('SUM')}
													</td>
												</tr>
											))
											}
										</tbody>
									</table>
								</div>
								:
								<ReactApexChart options={{ ...pieChartData.options, labels: pieChartData.options.topProductsIncomeLabel }} series={pieChartData.topProductsIncome} type="pie" height={350} />
							}
						</div>
					</div>

					<div className="card w-100">
						<div className="card-body">
							<h5>
								{t('TOP_PRODUCTS')} - {t('NUMBER_OF_CHECKS')}
							</h5>

							<div className="tabs_icons mt-2">
								<div className={"tab " + (pieChartData.showTopProductsChequeCount === 1 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsChequeCount: 1 })}>
									<i className="uil uil-list-ul"></i>
								</div>
								<div className={"tab " + (pieChartData.showTopProductsChequeCount === 2 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsChequeCount: 2 })}>
									<i className="uil uil-chart-pie-alt"></i>
								</div>
							</div>

							{pieChartData.showTopProductsChequeCount === 1 ?
								<div className="table-responsive mt-3">
									<table className="table">
										<thead>
											<tr>
												<th>{t('NAME2')}</th>
												<th className="text-end">{t('QUANTITY')}</th>
											</tr>
										</thead>
										<tbody>
											{topProducts?.topProductsChequeCount?.map((item, index) => (
												<tr key={index}>
													<td title={item.productName}>
														{item.rowNum}.
														{item.productName.substring(0, 17)}
														{item.productName.length > 17 && '...'}
													</td>
													<td className="text-end">
														{formatMoney(item.total, 0)}
													</td>
												</tr>
											))
											}
										</tbody>
									</table>
								</div>
								:
								<ReactApexChart options={{ ...pieChartData.options, labels: pieChartData.options.topProductsChequeCountLabel }} series={pieChartData.topProductsChequeCount} type="pie" height={350} />
							}
						</div>
					</div>

					<div className="card w-100">
						<div className="card-body">
							<h5>
								{t('TOP_PRODUCTS')} - {t('QUANTITY')}
							</h5>

							<div className="tabs_icons mt-2">
								<div className={"tab " + (pieChartData.showTopProductsQuantity === 1 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsQuantity: 1 })}>
									<i className="uil uil-list-ul"></i>
								</div>
								<div className={"tab " + (pieChartData.showTopProductsQuantity === 2 ? 'active' : '')}
									onClick={() => setPieChartData({ ...pieChartData, showTopProductsQuantity: 2 })}>
									<i className="uil uil-chart-pie-alt"></i>
								</div>
							</div>

							{pieChartData.showTopProductsQuantity === 1 ?
								<div className="table-responsive mt-3">
									<table className="table">
										<thead>
											<tr>
												<th>{t('NAME2')}</th>
												<th className="text-end">{t('QUANTITY')}</th>
											</tr>
										</thead>
										<tbody>
											{topProducts?.topProductsQuantity?.map((item, index) => (
												<tr key={index}>
													<td title={item.productName}>
														{item.rowNum}.
														{item.productName.substring(0, 17)}
														{item.productName.length > 17 && '...'}
													</td>
													<td className="text-end">
														{formatMoney(item.total, 0)}
													</td>
												</tr>
											))
											}
										</tbody>
									</table>
								</div>
								:
								<ReactApexChart options={{ ...pieChartData.options, labels: pieChartData.options.topProductsQuantityLabel }} series={pieChartData.topProductsQuantity} type="pie" height={350} />
							}
						</div>
					</div>
				</div>
			}

			{topProductsloader === 1 &&
				<>
					<div className="d-flex align-items-center gap-3 mb-3">
						<div className="w-100">
							<Skeleton height={460.57} />
						</div>
						<div className="w-100">
							<Skeleton height={460.57} />
						</div>
						<div className="w-100">
							<Skeleton height={460.57} />
						</div>
						<div className="w-100">
							<Skeleton height={460.57} />
						</div>
					</div>
				</>
			}

			{topProductsloader === 2 &&
				<div className="card">
					<div className="card-body">
						<h5>
							{t('POSITION')}
						</h5>

						<div className="table-responsive mt-3">
							<table className="table">
								<thead>
									<tr>
										<th>{t('NAME2')}</th>
										<th className="text-center">
											{t('SALES')}
										</th>
										<th className="text-center">
											{t('GROSS_INCOME')}
										</th>
										<th className="text-center">
											{t('NUMBER_OF_CHECKS')}
										</th>
										<th className="text-center">
											{t('QUANTITY')}
										</th>
									</tr>
								</thead>
								<tbody>
									{topProducts?.allProducts?.map((item, index) => (
										<tr key={index}>
											<td title={item.productName}>
												{item.rowNum}.
												{item.productName}
											</td>
											<td className="text-center">{item.totalAmountRank}</td>
											<td className="text-center">{item.totalIncomeRank}</td>
											<td className="text-center">{item.chequeCountRank}</td>
											<td className="text-center">{item.totalQuantityRank}</td>
										</tr>
									))
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			}

			{topProductsloader === 1 &&
				<div className="w-100">
					<Skeleton height={800} />
				</div>
			}
			{/* {loader === 0 &&
				<div className="d-flex justify-content-center mt-5" onClick={() => getData()}>
					<button className="btn-rounded btn btn-lg btn-primary">
						{t('GENERATE_REPORT')}
					</button>
				</div>
			} */}

		</>
	)
}

export default Index
