import React, { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { GET, POST, PUT } from 'helpers/api'
import { regexNumeric } from "helpers/helpers"

function CreateUpdate() {
	const { id } = useParams();
	const { t } = useTranslation()
	const navigate = useNavigate();

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [data, setData] = useState({
		userLogin: '',
		posList: [],
		wmsList: [],
	})

	const [roleTypeIndex, setRoleTypeIndex] = useState(0)

	const [accesses, setAccesses] = useState([])
	const [menu, setMenu] = useState([])
	const [poses, setPoses] = useState([])
	const [wmses, setWmses] = useState([])

	async function createUpdate(e) {
		e.preventDefault()

		var dataCopy = { ...data }
		if (dataCopy.phone && dataCopy.phone.length === 9)
			dataCopy.phone = '998' + regexNumeric(dataCopy.phone)

		var rolesList = []

		for (let i = 0; i < accesses.length; i++) {
			for (let j = 0; j < accesses[i].rolesList.length; j++) {
				const element = accesses[i].rolesList[j];
				if (element.checked) {
					rolesList.push(element)
					var options = element.options
					for (let k = 0; k < options?.length; k++) {
						if (options[k].checked) {
							rolesList.push(options[k])
						}
					}
				}
			}
		}
		dataCopy.rolesList = rolesList

		var response;
		if (dataCopy.id) {
			response = await PUT('/services/web/api/users', dataCopy, { loader: true })
		} else {
			const checkLogin = await GET(
				'/services/web/api/check-login?login=' + dataCopy.userLogin,
				{},
				{ loader: true, message: false }
			)

			if (!checkLogin.success) {
				return
			}

			response = await POST('/services/web/api/users', dataCopy, { loader: true })
		}

		if (response) {
			navigate(-1)
		}
	}

	async function handleMenuChange(option, roleTypeId) {
		var response
		if (id) {
			response = await GET(`/services/web/api/roles-helper?rolesId=${option.id}&usersId=${data.id}`, {}, { loader: true })
		} else {
			response = await GET(`/services/web/api/roles-helper?rolesId=${option.id}`, {}, { loader: true })
		}

		var dataCopy = { ...data }
		dataCopy.roleTypeId = roleTypeId
		getRoles(response, dataCopy)
	}

	function selectAll(checked) {
		var copyAccesses = [...accesses]
		for (let i = 0; i < copyAccesses[roleTypeIndex].rolesList.length; i++) {
			const element = copyAccesses[roleTypeIndex].rolesList[i];
			element.show = checked
			element.checked = checked
			var options = element['options']
			for (let i = 0; i < options?.length; i++) {
				options[i].checked = checked
			}
		}
		copyAccesses[roleTypeIndex].checked = checked
		setAccesses(copyAccesses)
	}

	function handleChangeAccess(index, index2, checked) {
		var copyAccesses = [...accesses]
		for (let i = 0; i < copyAccesses[roleTypeIndex].rolesList.length; i++) {
			const element = copyAccesses[roleTypeIndex].rolesList[i];
			if (index2 || index2 === 0) {
				if (index === i) {
					element['options'][index2]['checked'] = checked
				}
			} else {
				if (i === index) {
					element['checked'] = checked
					element['show'] = checked
					var options = element['options']
					for (let i = 0; i < options?.length; i++) {
						options[i].checked = checked
					}
				}
			}
		}
		setAccesses(copyAccesses)
	}

	async function getData() {
		if (id) {
			var response = await GET(`/services/web/api/users/${id}`, {}, { loader: true })
			response.phone = response?.phone?.substring(3, 12)

			const responseMenu = await GET(`/services/web/api/roles-helper?rolesId=${response.roleTypeId}&usersId=${response.id}`, {}, { loader: true })
			//response.rolesList = responseMenu
			getRoles(responseMenu, response)
		}
	}

	async function getMenu() {
		const response = await GET(`/services/web/api/menu-helper`, {}, { loader: true })
		setMenu(response)
	}

	async function getRoles(response, obj) {
		var dataCopy
		if (obj?.roleTypeId) {
			dataCopy = obj
		} else {
			dataCopy = { ...data }
		}

		var arr = [...response]
		for (let i = 0; i < response.length; i++) {
			var childrenArr = []
			for (let j = 0; j < response[i]?.rolesList?.length; j++) {
				const item = response[i].rolesList[j];
				item.child = false;
				if (item.parentId !== 0) {
					const index = childrenArr.findIndex(x => x.id === item.parentId)
					item.child = true;
					if (childrenArr[index]) {
						if (childrenArr[index]?.options && childrenArr[index]?.options.length > 0) {
							childrenArr[index].options.push(item)
						} else {
							childrenArr[index].options = [item]
						}
					}
				} else {
					if (item.checked) {
						item.show = true
					}

					childrenArr.push(item)
				}
			}
			response[i].rolesList = childrenArr;
		}
		// for (let i = 0; i < response.length; i++) {
		// 	for (let j = 0; j < response[i].rolesList.length; j++) {
		// 		var item = response[i].rolesList[j];
		// 		item.child = false;
		// 		if (item.parentId !== 0) {
		// 			const index = response[i].rolesList.findIndex(x => x.id === item.parentId)
		// 			item.child = true;
		// 			if (response[i].rolesList[index]) {
		// 				if (response[i].rolesList[index]?.options && response[i].rolesList[index]?.options.length > 0) {
		// 					response[i].rolesList[index].options.push(item)
		// 				} else {
		// 					response[i].rolesList[index].options = [item]
		// 				}
		// 			}
		// 		} else {
		// 			arr.push(item)
		// 		}
		// 	}
		// }
		dataCopy.rolesList = arr
		setData(dataCopy)
		setAccesses(arr)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getWmses() {
		const response = await GET('/services/wms/api/wms-helper')
		if (response) {
			setWmses(response)
		}
	}

	useEffect(() => {
		getData()
		getMenu()
		getPoses()
		getWmses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// function validatePassword(password) {
	// 	var regexm = new RegExp("^.*[a-zA-Z].*$")
	// 	var regexm2 = new RegExp("^.*[0-9].*$")

	// 	if (password === "") {
	// 		setValidData({ ...validData, 'isValid': 3 })
	// 	}
	// 	if (regexm.test(password) && regexm2.test(password)) {
	// 		setValidData({ ...validData, 'password': password, 'isValid': 4 })
	// 	} else {
	// 		setValidData({ ...validData, 'password': password, 'isValid': 5 })
	// 	}
	// }

	return (
		<>
			<div className="card">
				<div className="card-body">
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group">
									<label>{t('ROLE')}<span className="required-mark">*</span></label>
									<Select
										options={menu}
										value={menu.find(option => option.id === data.roleTypeId) || ''}
										placeholder=""
										onChange={(option) => {
											//setData({ ...data, 'roleTypeId': option.id })
											handleMenuChange(option, option.id)
										}}
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="firstName"
										value={data.firstName}
										onChange={(e) => setData({ ...data, 'firstName': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('LAST_NAME')}</label>
									<input type="text" className="form-control" name="lastName"
										value={data.lastName}
										onChange={(e) => setData({ ...data, 'lastName': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PHONE')}<span className="required-mark">*</span></label>
									<div className="input-group">
										<span className="input-group-text">998</span>
										<input type="number" className="form-control" name="phone"
											value={data.phone}
											onChange={(e) => setData({ ...data, 'phone': e.target.value })} />
									</div>
								</div>
								{!data.id &&
									<div className="form-group">
										<label>{t('LOGIN')}<span className="required-mark">*</span></label>
										<input type="text" className="form-control" name="userLogin"
											value={data.userLogin}
											onChange={(e) => setData({ ...data, 'userLogin': e?.target?.value?.toLowerCase()?.replace(/[^\w\s]/gi, '')?.replace(/ /g, '') })} />
									</div>
								}
								{!data.id &&
									<div className="form-group">
										<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
										<input type="text" className="form-control" name="password"
											value={data.password ?? ""}
											onChange={(e) => setData({ ...data, 'password': e.target.value })} />
									</div>
								}
								{data.id &&
									<div className="form-group">
										<label>{t('PASSWORD')}</label>
										<input type="text" className="form-control" name="password"
											value={data.password ?? ""}
											onChange={(e) => setData({ ...data, 'password': e.target.value })} />
									</div>
								}
								<div className="form-group">
									<label>{t('CURRENCY')}</label>
									<Select
										options={currencies}
										value={currencies.find(option => option.id === data.salaryCurrencyId)}
										onChange={(option) => setData({ ...data, 'salaryCurrencyId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('SALARY')}</label>
									<input type="text" className="form-control" name="salaryAmout"
										value={data.salaryAmout ?? ""}
										onChange={(e) => setData({ ...data, 'salaryAmout': e.target.value })} />
								</div>

								<div className="form-group">
									<label>{t('POS')}<span className="required-mark">*</span></label>
									<Select
										isMulti
										options={poses}
										value={data.posList}
										placeholder=""
										onChange={(options) => {
											setData({ ...data, 'posList': options })
										}}
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('WAREHOUSE')}</label>
									<Select
										isMulti
										options={wmses}
										value={data.wmsList}
										placeholder=""
										onChange={(options) => {
											setData({ ...data, 'wmsList': options })
										}}
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>

							</div>
							<div className="col-md-6" >
								<div className="d-flex justify-content-between align-items-center mb-4">
									<div className="workers-tabs">
										{accesses.map((item, index) => (
											<div className={"tab " + (roleTypeIndex === index ? 'active' : '')} key={index}
												onClick={() => setRoleTypeIndex(index)}>
												{item?.serviceName}
											</div>
										))}
									</div>

								</div>
								{accesses[roleTypeIndex]?.rolesList.length > 0 &&
									<div style={{ 'height': '500px', 'overflow': 'auto' }}>
										{accesses.map((item, index) => (
											index === roleTypeIndex &&
											<div className="d-flex justify-content-between mb-2" key={index}>
												<h4>{t('SELECT_ALL')}</h4>
												<div className="form-check" key={index}>
													<input className="form-check-input" type="checkbox" id="flexCheckChecked"
														checked={item.checked ? true : false}
														onChange={(e) => selectAll(e.target.checked)} />
												</div>
											</div>
										))}

										{accesses[roleTypeIndex]?.rolesList?.map((item, index) => (
											<div key={index} className="mb-2">
												<div className="d-flex justify-content-between">
													<h5>{item.name}</h5>
													<div className="form-check">
														<input className="form-check-input" type="checkbox" id="flexCheckChecked"
															checked={item.checked ? true : false}
															onChange={(e) => handleChangeAccess(index, null, e.target.checked)} />
														<label className="form-check-label" htmlFor="flexCheckChecked"></label>
													</div>
												</div>
												{item?.show &&
													item?.options?.map((item2, index2) => (
														<div className="d-flex justify-content-between" key={index2}>
															<div className="ms-3 fz-16 text-black-5">
																{item2.name}
															</div>
															<div className="form-check">
																<input className="form-check-input" type="checkbox" id="flexCheckChecked"
																	checked={item2.checked ? true : false}
																	onChange={(e) => handleChangeAccess(index, index2, e.target.checked)} />
																<label className="form-check-label" htmlFor="flexCheckChecked"></label>
															</div>
														</div>
													))
												}
											</div>
										))}
									</div>
								}
							</div>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-outline-danger btn-wide" to="/workers">
								{t('CANCEL')}
							</Link>
							<button type="submit" className="btn btn-outline-primary btn-wide btn-wide">
								{t('SAVE')}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate