import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

//import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { POST } from 'helpers/api'

function TableControl({ modalTableControl, setModalTableControl, keyName }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxSettings = useSelector(state => state.settings)

	//const [selectedTable, setSelectedTable] = useState({})
	const [settings, setSettings] = useState({
		'size': 20,
		'decimalPoint': 2,
		'currencyId': "",
		'posId': "",
		'organizationId': "",
		'rememberLastDocumentInPreview': null,
		'tableNomenclature': {
			'posName': true,
			'organizationName': true,
			'wholesalePrice': true,
			'bankPrice': false,
			'currencyName': true,
			'serial': true,
			'expDate': true,
			'balance': true,
			'artikul': false,
			'categoryName': false,
			'pos': true,
		},
		'tableRevaluation': {
			'artikul': false,
			'pos': true,
			'bankPrice': false,
			'categoryName': false,
		},
		'tableHistory': {
			'artikul': false,
			'pos': true,
			'bankPrice': false,
			'categoryName': false,
		},
		'tableScale': {
			'artikul': false,
			'pos': true,
		},
		'tableGrouping': {
			'pos': true,
		},
		'tableNotfications': {
			'artikul': false,
			'pos': true,
		},
		'tableReport': {
			'artikul': false,
			'pos': true,
			'categoryName': false,
		},
		'tableDocumentsInCreate': {
			'uomName': true,
			'wholesalePrice': true,
			'serial': true,
			'expDate': true,
			'vat': true,
			'artikul': false,
			'originalPrice': false,
			'bankPrice': false,
			'marking': false,
		},
		'tableDocumentsInPreview': {
			'serial': true,
			'expDate': true,
			'wholesalePrice': true,
			'vat': true,
			'artikul': false,
			'originalPrice': false,
			'bankPrice': false,
			'marking': false,
		},
		'tableReportIncomes': {
			'currencyName': true,
			'vat': true,
			'vatAmount': true,
			'artikul': false,
			'pos': true,
			'bankPrice': false,
			'expense': false,
			'transfer': false,
			'categoryName': false,
		},
		'tableTransfer': {
			'serial': true,
			'expDate': true,
			'wholesalePrice': true,
			'vat': true,
			'artikul': false,
			'bankPrice': false,
			'categoryName': false,
		},
		'tableReportSales': {
			'agent': true,
			'cashier': true,
			'currencyName': true,
			'discount': true,
			'artikul': true,
			'categoryName': true,
		},
		'tableReportExpirationDate': {
			'artikul': true,
			'categoryName': false,
		},
		'tableReportReturnProducts': {
			'artikul': true,
			'categoryName': false,
		},
		'tableReportGrossIncome': {
			'artikul': true,
			'categoryName': false,
		},
		'tableReportCategoryManagment': {
			'artikul': true,
			'categoryName': false,
		},
		'tableDocumentsIn': {
			'pos': true,
			'inNumber': true,
			'note': false,
			'currencyName': true,
			'createdBy': true,
		},
	})

	function handleChange(e) {
		var settingsCopy = { ...settings }
		settingsCopy[keyName][e.target.name] = e.target.checked
		setSettings(settingsCopy)
	}

	async function saveSettings() {
		var sendData = {
			'settings': JSON.stringify(settings)
		}
		const response = await POST("/services/web/api/user-settings", sendData, { loader: true })
		if (response) {
			dispatch({ type: 'SET_SETTINGS', payload: settings })
			setSettings(settings)
			setModalTableControl(false)
		}
	}

	useEffect(() => {
		if (
			settings &&
			reduxSettings &&
			Object.keys(settings).length === Object.keys(reduxSettings).length) {
			setSettings(reduxSettings)
		} else {
			for (var key in settings) {
				if (reduxSettings.hasOwnProperty(key)) { //IF OBJECT HAVE ALL KEYS
					if (
						settings[key] &&
						reduxSettings[key] &&
						Object.keys(settings[key]).length !== Object.keys(reduxSettings[key]).length
					) { //IF OBJECT OF OBJECT HAVE ALL KEYS

						for (var tableKey in settings[key]) {
							// if (reduxSettings[key].hasOwnProperty(tableKey)) {
							reduxSettings[key][tableKey] = settings[key][tableKey]
							// }
						}
						if (Object.keys(settings[key]).length < Object.keys(reduxSettings[key]).length) {
							for (tableKey in reduxSettings[key]) {
								if (!settings[key].hasOwnProperty(tableKey)) {
									if (tableKey !== '0') {
										delete reduxSettings[key][tableKey]
									}
								}
							}
						}
					}
					// if (Object.keys(reduxSettings[key])?.length > Object.keys(settings[key])?.length) {
					// 	console.log(reduxSettings[key]);
					// }
				} else {
					reduxSettings[key] = settings[key]
				}
			}
		}
		setSettings(reduxSettings)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	// useDidMountEffect(() => {
	// 	setSettings(reduxSettings)
	// }, [reduxSettings]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{/* MODAL TABLE CONTROL */}
			<Modal show={modalTableControl} animation={false} centered size="sm" onHide={() => setModalTableControl(false)}>
				<Modal.Body>
					{(settings[keyName] && (typeof settings[keyName]['wholesalePrice'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('WHOLESALE_PRICE')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="wholesalePrice"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['wholesalePrice']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['currencyName'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('CURRENCY')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="currencyName"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['currencyName']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['serial'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('SERIAL')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="serial"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['serial']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['expDate'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('EXPIRATION_DATE')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="expDate"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['expDate']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['vat'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('VAT')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="vat"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['vat']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['vatAmount'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('AMOUNT')} {t('VAT')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="vatAmount"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['vatAmount']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['cashier'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('CASHIER')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="cashier"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['cashier']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['agent'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('AGENT')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="agent"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['agent']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['artikul'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('ARTIKUL')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="artikul"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['artikul']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['categoryName'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('CATEGORY')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="categoryName"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['categoryName']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['originalPrice'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('ORIGINAL_PRICE')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="originalPrice"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['originalPrice']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['bankPrice'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('BANK_PRICE')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="bankPrice"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['bankPrice']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['marking'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('MARKING')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="marking"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['marking']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['pos'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('POS')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="pos"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['pos']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['expense'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('EXPENSES')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="expense"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['expense']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['transfer'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('DISPLACEMENT')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="transfer"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['transfer']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['inNumber'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								№ {t('OVERHEAD')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="inNumber"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['inNumber']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['note'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('NOTE')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="note"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['note']} />
							</div>
						</div>
					}
					{(settings[keyName] && (typeof settings[keyName]['createdBy'] === "boolean")) &&
						<div className="d-flex justify-content-between mb-2">
							<div className="vertical-center">
								{t('CREATED_BY')}
							</div>
							<div className="vertical-center">
								<input type="checkbox" name="createdBy"
									onChange={(e) => handleChange(e)}
									checked={settings[keyName]['createdBy']} />
							</div>
						</div>
					}
					<div className="d-flex justify-content-center w-100 mt-3">
						<button className="btn-sm btn-primary w-50" onClick={() => saveSettings()}>{t('SAVE')}</button>
					</div>
				</Modal.Body>
			</Modal>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default TableControl