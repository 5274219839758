import React, { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Select from 'react-select'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { GET, POST, PUT, DELETE } from 'helpers/api'
import { DebounceInput } from "react-debounce-input"
import { checkRole, formatMoney } from "helpers/helpers"

function CreateUpdate() {
	const { id } = useParams();
	const { t } = useTranslation()
	const navigate = useNavigate();

	const canDelete = checkRole('POS_PRODUCT_BOX_DELETE')

	const { register, handleSubmit, formState, control, setValue, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {
			productId: '',
			posId: '',
			wholesalePrice: '',
			salePrice: '',
			quantity: '0',
		}
	});
	const { errors } = formState

	const [search, setSearch] = useState('')

	const [searchDataExist, setSearchDataExist] = useState(0)

	const [searchData, setSearchData] = useState([])
	const [products, setProducts] = useState([])
	const [poses, setPoses] = useState([])

	const [data, setData] = useState({
		productId: '',
		posId: '',
		wholesalePrice: '',
		salePrice: '',
		quantity: '0',
		itemList: []
	})

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		sendData.itemList = data.itemList
		if (sendData.id) {
			response = await PUT('/services/web/api/product-box', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/product-box', sendData, { loader: true })
		}

		if (response) {
			navigate('/package')
		}
	}

	function handleProductListChange(e, index) {
		var arr = [...data.itemList]
		arr[index][e.target.name] = e.target.value.replace(/,/g, '.').replace(/[^0-9.]/g, '')
		countTotalAmount(arr)
	}

	async function deleteProduct(index) {
		var dataCopy = { ...data }
		if (id) {
			await DELETE(`/services/web/api/product-box-item/${dataCopy.itemList[index]['id']}`, {}, { loader: true })
		}
		dataCopy.itemList.splice(index, 1)
		countTotalAmount(dataCopy.itemList)
		// setData(dataCopy)
	}

	function addToList(index) {
		var arr = [...data.itemList]
		var i = arr.findIndex(item => item.productId === searchData[index].productId)
		if (i !== -1) {
			arr[i].quantity = Number(arr[i].quantity) + 1
		} else {
			searchData[index].quantity = 1
			arr.push(searchData[index])
		}

		countTotalAmount(arr)
		setSearch('')
		setSearchData([])
	}

	function countTotalAmount(arr) {
		var totalAmount = 0;
		var totalAmountSalePrice = 0;
		for (let i = 0; i < arr.length; i++) {
			totalAmount += (Number(arr[i].price) * Number(arr[i].quantity))
			totalAmountSalePrice += (Number(arr[i].salePrice) * Number(arr[i].quantity))
		}
		setData({ ...data, itemList: arr, totalAmount: totalAmount, totalAmountSalePrice: totalAmountSalePrice, productBox: true })
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await GET(`/services/web/api/product-helper-price/${getValues('posId')}`, { 'search': value, }, { loader: true })
			if (response) {
				setSearchData(response)
			}
		}
	}

	async function getData() {
		const response = await GET('/services/web/api/product-box/' + id, {}, { loader: true })
		for (const [key, value] of Object.entries(response)) {
			setValue(key, value)
		}

		if (response.itemList?.length > 0) {
			countTotalAmount(response.itemList)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
			setValue('posId', response[0]?.id)
		}
	}

	async function getProducts() {
		const response = await GET('/services/web/api/product-box-helper')
		if (response) {
			setProducts(response)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		}
		getPoses()
		getProducts()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps


	return (
		<>
			<div className="card">
				<div className="card-body">
					<form autoComplete="off">
						<div className="row">
							<div className="col-md-6">
								<div className="d-flex">
									<div className="form-group w-100 me-3">
										<label>{t('PRODUCTS')}</label>
										<Controller
											name="productId"
											control={control}
											render={({ field }) =>
												<Select
													//isDisabled={id}
													tabIndex={3}
													{...field}
													options={products}
													value={products.find(option => option.id === getValues('productId'))}
													onChange={(e) => setValue('productId', e.id)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													isDisabled={id}
												/>}
										/>
										<span className="text-danger text-sm">{errors.productId?.message}</span>
									</div>
									<div className="form-group w-100">
										<label>{t('POS')}</label>
										<Controller
											name="posId"
											control={control}
											render={({ field }) =>
												<Select
													//isDisabled={id}
													tabIndex={3}
													{...field}
													options={poses}
													value={poses.find(option => option.id === getValues('posId'))}
													onChange={(e) => setValue('posId', e.id)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
													isDisabled={id}
												/>}
										/>
										<span className="text-danger text-sm">{errors.productId?.message}</span>
									</div>
								</div>

								<div className="d-flex">
									<div className="form-group w-100 me-3">
										<label>{t("SALE_PRICE")}<span className="required-mark">*</span></label>
										<input type="number" className="form-control" tabIndex="1" name="name" {...register('salePrice')} />
										<span className="text-danger text-sm">{errors.salePrice?.message}</span>
									</div>
									<div className="form-group w-100 me-3">
										<label>{t("WHOLESALE_PRICE")}<span className="required-mark">*</span></label>
										<input type="number" className="form-control" tabIndex="1" name="name" {...register('wholesalePrice')} />
										<span className="text-danger text-sm">{errors.wholesalePrice?.message}</span>
									</div>
									<div className="form-group w-100">
										<label>{t("RESIDUE")}<span className="required-mark">*</span></label>
										<input type="number" className="form-control" tabIndex="1" name="name" {...register('quantity')} />
										<span className="text-danger text-sm">{errors.quantity?.message}</span>
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<div className="input-borer-only p-3 my-3">
									<h4 className="mb-3"><strong>{t('BUILD_SET')}</strong></h4>
									<div className="form-group position-relative m-0 mb-3">
										<DebounceInput
											value={search}
											onChange={(e) => searchProduct(e.target.value)}
											onKeyUp={(e) => {
												if (e.keyCode === 13) {
													e.preventDefault()
													searchProduct(e.target.value)
												}
											}}
											type="text"
											className="form-control"
											placeholder={t('SEARCH') + '...'}
											debounceTimeout={1000}
											disabled={!getValues('posId') || getValues('id')}
										/>

										{searchData.length > 0 &&
											<span className="dropdown-search-menu">
												<div className="dropdown-menu-list">
													<span className="dropdown-menu-result">
														<div className="table-responsive p-3">
															<table className="table-striped table">
																<thead>
																	<tr>
																		<th>{t('NAME_OF_PRODUCT')}</th>
																		<th className="text-center">{t('ACTION')}</th>
																	</tr>
																</thead>
																<tbody>
																	{searchData.map((item, index) => (
																		<tr key={index}>
																			<td>{item.nameBarcode}</td>
																			<td>
																				<div className="table-actions">
																					<div className="table-action-button table-action-primary-button" onClick={() => addToList(index)}>
																						<i className="uil uil-plus"></i>
																					</div>
																				</div>
																			</td>
																		</tr>
																	))
																	}
																</tbody>
															</table>

															{/* <Pagination pageCount={pageCount} paginate={paginate}></Pagination> */}
														</div>
													</span>
												</div>
											</span>
										}
										{(searchData.length === 0 && searchDataExist === 1) &&
											<span className="dropdown-search-menu">
												<div className="dropdown-menu-list d-flex justify-content-between p-2">
													<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
												</div>
											</span>
										}

									</div>

									<div className="table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th>{t('NAME2')}</th>
													<th className="text-center">{t('QUANTITY')}</th>
													<th className="text-center">{t('PRICE')}</th>
													<th className="text-center">{t('ACTION')}</th>
												</tr>
											</thead>
											<tbody>
												{data?.itemList?.map((item, index) => (
													<tr key={index}>
														<td>{item.nameBarcode}</td>
														<td className="text-center">
															<input type="text" className={"auto-width-input " + (!item.quantity && "required-border")}
																disabled={getValues('id')}
																name="quantity" value={item.quantity}
																id={index + 1}
																onChange={(e) => handleProductListChange(e, index)} />
														</td>
														<td className="text-center">{formatMoney(item.price)}</td>
														<td>
															<div className="table-actions">
																{canDelete &&
																	<div className={"table-action-button table-action-danger-button " + (getValues('id') ? 'disabled' : '')} onClick={() => deleteProduct(index)}>
																		<i className="uil uil-trash-alt"></i>
																	</div>
																}
															</div>
														</td>
													</tr>
												))
												}

											</tbody>
										</table>

										<div className="text-end fz18 px-4">
											{data.totalAmount > 0 &&
												<b>{t('ORIGINAL_PRICE')}: {formatMoney(data.totalAmount)}</b>
											}
										</div>
										<div className="text-end fz18 px-4">
											{data.totalAmountSalePrice > 0 &&
												<b>{t('SALE_PRICE')}: {formatMoney(data.totalAmountSalePrice)}</b>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="btn-wrapper">
							<Link className="btn btn-outline-danger btn-wide me-2" to="/package">
								{t('CANCEL')}
							</Link>
							<button type="button" className="btn btn-outline-primary btn-wide btn-wide" tabIndex="4" onClick={handleSubmit(createUpdate)}>{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>

		</>
	)
}

export default CreateUpdate