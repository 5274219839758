import React, { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import XLSX from 'xlsx'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import { SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW } from "store/settings/actions"

import { PGET, GET } from 'helpers/api'
import { checkRole, formatDate, formatDateWithTime, formatMoney } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const settings = useSelector(state => state.settings)

	const canCreate = checkRole('WMS_DOCUMENT_IN_ADD')

	const [pagination] = useState({
		url: '/services/wms/api/documents-in-pageList',
		response: null
	})
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		const response = await GET('/services/wms/api/documents-in/' + item.id + '?size=10000')

		var excelHeaders = [[t('WAREHOUSE'), t('SUPPLIER'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('UNIT'), t('ADMISSION_PRICE'),
		t('SERIAL'), t('EXPIRATION_DATE'), t('QUANTITY'), t('WHOLESALE_PRICE'),
		t('UNIT_PRICE'), t('VAT'), t('AMOUNT')]]
		for (let i = 0; i < response.productList.content.length; i++) {
			var excelItems = []
			excelItems.push(item.posName)
			excelItems.push(item.organizationName)
			excelItems.push(response.productList.content[i].productName)
			excelItems.push(response.productList.content[i].barcode)
			excelItems.push(response.productList.content[i].uomName)
			excelItems.push(response.productList.content[i].price)
			excelItems.push(response.productList.content[i].serial)
			excelItems.push(formatDate(response.productList.content[i].expDate))
			excelItems.push(response.productList.content[i].quantity)
			excelItems.push(response.productList.content[i].wholesalePrice)
			excelItems.push(response.productList.content[i].salePrice)
			excelItems.push(response.productList.content[i].vat)
			excelItems.push(response.productList.content[i].totalAmount)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('GOOD_RECEPTION') + ".xlsx");
	}

	async function getData() {
		const response = await PGET(pagination.url, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	function redirectPreview(id) {
		dispatch(SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW(id))
		navigate(`/wms/documents-in/preview/${id}`)
	}

	async function paginate(data) {
		// var urlParams = ""
		// if (categoryId)
		// 	urlParams += returnSign(urlParams) + 'categoryId=' + categoryId
		const response = await PGET(pagination.url, { page: data.selected, size: settings.size })
		setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-between mb-3">
						<Search url={pagination.url} search={search} getData={getData}></Search>

						{canCreate &&
							<Link className="btn btn-outline-primary" to="/wms/documents-in/create">
								{t('CREATE')}
							</Link>
						}
					</div>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('WAREHOUSE')}</th>
									<th>{t('SUPPLIER')}</th>
									<th className="text-center">№ {t('DOCUMENT')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RECEIPT')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>
											<span className={settings?.rememberLastDocumentInPreview === item.id ? 'border-bottom-primary' : ''}
												title={settings?.rememberLastDocumentInPreview === item.id ? t('LAST_SEEN_DOCUMENT') : ''}>
												{item.rowNum}. {item.wmsName}
											</span>
										</td>
										<td>{item.organizationName}</td>
										<td className="text-center">{item.inNumber}</td>
										<td className="text-center">{formatMoney(item.totalAmount)} {item.currencyName}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td>
											<div className="table-actions">
												<div className="table-action-button table-action-primary-button"
													onClick={() => redirectPreview(item.id)}>
													<i className="uil-eye"></i>
												</div>
												<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
													<i className="uil-file-alt"></i>
												</div>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
