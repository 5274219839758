import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { PGET, GET, POST, PUT } from 'helpers/api'
import { checkRole, formatMoney } from 'helpers/helpers'

function Index() {
	const URL = '/services/web/api/currency-list'
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {}
	});
	const { errors } = formState

	const settings = useSelector(state => state.settings)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const canCreate = checkRole('POS_SALARY_ADD')
	const canUpdate = checkRole('POS_SALARY_EDIT')

	const [showModal, setShowModal] = useState(false)

	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [users, setUsers] = useState([])
	const [data, setData] = useState([])

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/salary', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/salary', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function getData() {
		const response = await PGET(`/services/web/api/salary-list`, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getUsers() {
		const response = await GET('/services/web/api/users-helper')
		if (response) {
			setUsers(response)
		}
	}

	async function paginate(data) {
		const response = await PGET(`/services/web/api/salary-list`, { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
		getUsers()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-between mb-3">
						<div className="d-flex">
							<div className="me-2">
								<Search url={URL} search={search} getData={getData}></Search>
							</div>
						</div>

						{canCreate &&
							<button className="btn btn-outline-primary" onClick={() => setShowModal(true)}>
								{t('CREATE')}
							</button>
						}
					</div>


					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('USER')}</th>
									<th className="text-center">{t('ROLE')}</th>
									<th className="text-center">{t('CURRENCY')}</th>
									<th className="text-center">{t('SALARY')}</th>
									<th className="text-center">{t('WORKING_DAYS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.posName}</td>
										<td className="text-center">{item.userName}</td>
										<td className="text-center">{item.roleName}</td>
										<td className="text-center">{item.salaryCurrencyName}</td>
										<td className="text-center">{formatMoney(item.salaryAmount)}</td>
										<td className="text-center">{item.workDays}</td>
										<td>
											<div className="table-actions">
												{canUpdate &&
													<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
														<i className="uil uil-edit-alt"></i>
													</div>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('id')}
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label>{t('USER')}<span className="required-mark">*</span></label>
							<Controller
								name="userId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={users}
										value={users.find(option => option.id === getValues('userId')) || ''}
										onChange={(e) => setValue('userId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('id')}
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
							<Controller
								name="salaryCurrencyId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={currencies}
										value={currencies.find(option => option.id === getValues('salaryCurrencyId')) || ''}
										onChange={(option) => setValue('salaryCurrencyId', option.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								}
							/>
							<span className="text-danger text-sm">{errors.salaryCurrencyId?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('SALARY')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="salaryAmount" {...register('salaryAmount')} />
							<span className="text-danger text-sm">{errors.salaryAmount?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('WORKING_DAYS')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="workDays" {...register('workDays')} />
							<span className="text-danger text-sm">{errors.workDays?.message}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
