import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { Dropdown, Modal } from 'react-bootstrap'
import XLSX from 'xlsx';

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { GET } from 'helpers/api'
import { formatMoney, returnSign, formatDate, formatDateBackend } from 'helpers/helpers'

function Summary() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [showDebtModal, setShowDebtModal] = useState(false)
	const [showOrganizationModal, setShowOrganizationModal] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		reportDate: null,
	})
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [debt, setDebt] = useState({
		paymentList: []
	})

	async function getModalData(settings = { url: '', type: 'debt', isTotal: false, item: {} }) {
		var urlParams = ""
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (settings.item?.reportDate)
			urlParams += returnSign(urlParams) + 'startDate=' + settings.item.reportDate
		if (settings.item?.reportDate)
			urlParams += returnSign(urlParams) + 'endDate=' + settings.item.reportDate
		if (settings.isTotal) {
			urlParams += returnSign(urlParams) + 'startDate=' + data?.reportList[0]?.reportDate
			urlParams += returnSign(urlParams) + 'endDate=' + data?.reportList[data?.reportList?.length - 1]?.reportDate
		}
		const response = await GET(settings.url + urlParams)
		if (response) {
			setDebt(response)
			if (settings.type === 'debt') {
				setShowDebtModal(true)
			} else {
				setShowOrganizationModal(true)
			}
		}
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = `/services/web/api/report-summary-total`
		var urlParams = ""

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.reportDate)
			urlParams += returnSign(urlParams) + 'reportDate=' + filterData.reportDate

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-summary-total/" + urlParams + returnSign(filterUrl)
			var children = document.getElementById('thead').childNodes
			var excelHeaders = [[]]
			for (let i = 0; i < children.length; i++) {
				excelHeaders[0].push(children[i]?.innerHTML?.toString())
			}
			// [ t('DATE'), t('GOOD_RECEPTIONED'), t('RETURN_OF_GOODS'), t('SALES'), t('CASH'), t('TERMINAL'), t('BORROWED'),
			// 'moneyBek ' + t('BONUS'), t('DEBT_RETURNED'), t('PAYMENT_LOYALTY'), t('CONSUMPTION'), t('GROSS_INCOME'), t('TOTAL'), ]

			const response = await GET(filterUrl)
			for (let i = 0; i < response.reportList.length; i++) {
				var excelItems = []
				excelItems.push(formatDate(response.reportList[i].reportDate))
				excelItems.push(formatMoney(response.reportList[i].inAmount))
				excelItems.push(formatMoney(response.reportList[i].outAmount))
				excelItems.push(formatMoney(response.reportList[i].organizationOutAmount))
				excelItems.push(formatMoney(response.reportList[i].salesAmount))
				excelItems.push(formatMoney(response.reportList[i].returnAmount))
				excelItems.push(formatMoney(response.reportList[i].cashAmount))
				excelItems.push(formatMoney(response.reportList[i].cardAmount))
				excelItems.push(formatMoney(response.reportList[i].onlineAmount))
				excelItems.push(formatMoney(response.reportList[i].debtOutAmount))
				excelItems.push(formatMoney(response.reportList[i].loyaltyClientAmount))
				excelItems.push(formatMoney(response.reportList[i].grossIncome))
				excelItems.push(formatMoney(response.reportList[i].discountAmount))
				excelItems.push(formatMoney(response.reportList[i].writeOffAmount))
				excelItems.push(formatMoney(response.reportList[i].expenseAmount))
				excelItems.push(formatMoney(response.reportList[i].debtInAmount))
				excelItems.push(formatMoney(response.reportList[i].loyaltyBonus))
				excelItems.push(formatMoney(response.reportList[i].totalAmount))
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('SUMMARY') + ".xlsx");
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response)
			}
		}

		setDropdownIsOpen(false)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div className="d-flex">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														showMonthYearPicker
														showFullMonthYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.reportDate ? new Date(filterData.reportDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'reportDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary"
														disabled={!(filterData.posId && filterData.reportDate)}
														onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
						</div>
					</div>

					<div className="table-responsive table-sticky">
						<table className="table table-striped mb-0">
							<thead className="sticky-top">
								<tr id="thead" >
									<th>{t('DATE')}</th>
									<th className="text-center">{t('GOOD_RECEPTION')}</th>
									<th className="text-center">{t('RETURN_TO_SUPPLIER')}</th>
									<th className="text-center">{t('PAID_TO_SUPPLIER')}</th>
									<th className="text-center">{t('SALE')}</th>
									<th className="text-center">{t('RETURN')}</th>
									<th className="text-center">{t('CASH')}</th>
									<th className="text-center">{t('TERMINAL')}</th>
									<th className="text-center">{t('ONLINE')}</th>
									<th className="text-center">{t('ON_CREDIT')}</th>
									<th className="text-center">uGet {t('PAYMENT_2')}</th>
									<th className="text-center">{t('GROSS_INCOME')}</th>
									<th className="text-center">{t('DISCOUNT')}</th>
									<th className="text-center">{t('WRITE_OFF')}</th>
									<th className="text-center">{t('OUT_BALANCE')}</th>
									<th className="text-center">{t('DEBT_RETURNED')}</th>
									<th className="text-center">uGet {t('BONUS')}</th>
									<th className="text-center">{t('NET_PROFIT')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.reportList?.map((item, index) => (
									<tr key={index}>
										<td>{item.reportDate}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-organization-debt', type: 'organization', item: item })}>
											{formatMoney(item.inAmount)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(item.outAmount)}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-organization-debt', type: 'organization', item: item })}>
											{formatMoney(item.organizationOutAmount, 0)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(item.salesAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.returnAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.cashAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.cardAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.onlineAmount, 0)}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-client-debt', type: 'debt', item: item })}>
											{formatMoney(item.debtOutAmount, 0)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(item.loyaltyClientAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.grossIncome, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.discountAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.writeOffAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.expenseAmount, 0)}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-client-debt', type: 'debt', item: item })}>
											{formatMoney(item.debtInAmount, 0)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(item.loyaltyBonus, 0)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmount, 0)}</td>
									</tr>
								))
								}
								{data?.reportList?.length > 0 &&
									<tr className="tr-bold">
										<td><b>{t('TOTAL')}</b></td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-organization-debt', type: 'organization', isTotal: true })}>
											{formatMoney(data.inAmountTotal)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(data.outAmountTotal)}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-organization-debt', type: 'organization', isTotal: true })}>
											{formatMoney(data.organizationOutAmountTotal, 0)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(data.salesAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.returnAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.cashAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.cardAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.onlineAmountTotal, 0)}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-client-debt', type: 'debt', isTotal: true })}>
											{formatMoney(data.debtOutAmountTotal, 0)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(data.loyaltyClientAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.grossIncomeTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.discountAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.writeOffAmountTotal, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(data.expenseAmountTotal, 0)}</td>
										<td className="text-center text-nowrap cursor" onClick={() => getModalData({ url: '/services/web/api/report-summary-client-debt', type: 'debt', isTotal: true })}>
											{formatMoney(data.debtInAmountTotal, 0)}
										</td>
										<td className="text-center text-nowrap">{formatMoney(data.loyaltyBonusTotal, 0)}</td>
										<td className="text-end text-nowrap">{formatMoney(data.totalAmountTotal, 0)}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<Modal show={showDebtModal} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowDebtModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('ON_CREDIT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<tr>
								<th>№ {t('CLIENT')}</th>
								<th className="text-end">{t('ON_CREDIT')}</th>
								<th className="text-end">{t('AMORTIZATION')}</th>
							</tr>
						</thead>
						<tbody>
							{debt.paymentList?.content?.map((item, index) => (
								<tr key={index}>
									<td>{item.rowNum}. {item.name}</td>
									<td className="text-danger text-end">{formatMoney(item.amountOut)}</td>
									<td className="text-success text-end">{formatMoney(item.amountIn)}</td>
								</tr>
							))
							}
							<br />
							<tr>
								<td><b>{t('TOTAL')}</b></td>
								<td className="text-danger text-end"><b>{formatMoney(debt.outTotalAmount)}</b></td>
								<td className="text-success text-end"><b>{formatMoney(debt.inTotalAmount)}</b></td>
							</tr>
						</tbody>
					</table>
				</Modal.Body>
			</Modal>

			<Modal show={showOrganizationModal} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowOrganizationModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{' '}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<tr>
								<th>№ {t('SUPPLIERS')}</th>
								<th className="text-end">{t('IN_BALANCE')}</th>
								<th className="text-end">{t('OUT_BALANCE')}</th>
							</tr>
						</thead>
						<tbody>
							{debt.paymentList?.content?.map((item, index) => (
								<tr key={index}>
									<td>{item.rowNum}. {item.name}</td>
									<td className="text-danger text-end">{formatMoney(item.amountIn)}</td>
									<td className="text-success text-end">{formatMoney(item.amountOut)}</td>
								</tr>
							))
							}
							<br />
							<tr>
								<td><b>{t('TOTAL')}</b></td>
								<td className="text-danger text-end"><b>{formatMoney(debt.inTotalAmount)}</b></td>
								<td className="text-success text-end"><b>{formatMoney(debt.outTotalAmount)}</b></td>
							</tr>
						</tbody>
					</table>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default Summary