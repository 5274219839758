import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from "pages/parts/useDidMountEffect"

import { Modal, Dropdown } from 'react-bootstrap'
import Search from 'pages/parts/Search'
import Select from 'react-select'

import { Controller, useForm } from "react-hook-form"

import { GET, PGET, POST, PUT } from 'helpers/api'
import { checkRole, findFromArrayById, formatMoney } from 'helpers/helpers'
import { Link } from "react-router-dom"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const operationTypes = [{ "id": 1, "name": t('IN_BALANCE') }, { "id": 2, "name": t('OUT_BALANCE') }]

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange"
	});
	const { errors } = formState

	const reduxAccount = useSelector(state => state.account)

	const canCreate = checkRole('POS_BANK_ADD')

	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [modalPayment, setModalPayment] = useState(false)

	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
		page: 0,
		random: 0
	})

	const [selectedItem, setSelectedItem] = useState({
		operationType: 2,
		amount: '',
		note: '',
	})

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [banks, setbanks] = useState([])

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		var sendData = {}
		sendData.bankId = selectedItemCopy.bankId
		sendData.operationType = selectedItemCopy.operationType
		sendData.amount = selectedItemCopy.amount
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/web/api/bank-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	function showPaymentModal(bool) {
		if (!bool) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.operationType = 2
			selectedItemCopy.amount = ''
			selectedItemCopy.note = ''
			setSelectedItem(selectedItemCopy)
		}
		console.log(selectedItem);
		setModalPayment(bool)
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/bank', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/bank', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}


	// function openModal(item) {
	// 	var itemCopy = { ...item }
	// 	for (const [key, value] of Object.entries(itemCopy)) {
	// 		setValue(key, value)
	// 	}
	// 	setValue('id', item.bankId)
	// 	setShowModal(true)
	// }

	function closeModal() {
		setShowModal(false)
		reset()
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		var filterDataCopy = { ...filterData }
		const response = await PGET('/services/web/api/bank-list', filterDataCopy, { loader: true })
		if (response) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			setPoses(response)
		}
	}

	async function getbanks() {
		const response = await GET("/services/web/api/bank-helper")
		if (response) {
			setbanks(response)
			setSelectedItem({ ...selectedItem, bankId: response[0]?.bankId })
		}
	}

	async function search(data) {
		setData(data)
	}

	useEffect(() => {
		getData()
		getPoses()
		getbanks()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'documentsIn', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-between mb-3">
						<div className="d-flex">
							<div className="me-2">
								<Search url={'/services/web/api/clients-pageList'} search={search} getData={getData}></Search>
							</div>
							<div>
								<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-12">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-outline-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
														{t('APPLY')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={showPaymentModal}>
									{t('PAY')}
								</button>
							</div>
						</div>

						{canCreate &&
							<button className="btn btn-outline-primary" onClick={() => setShowModal(true)}>
								{t('CREATE')}
							</button>
						}
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}
				</div>
			</div>

			<div className="row mt-3">
				{data?.map((item, index) => (
					<Link to={'/bank/history/' + item.bankId} className="col-md-4 mb-3 text-black" key={index}>
						<div className="card border-radius-3 shadow">
							<div className="card-body d-flex align-items-center gap-3 px-4 cursor">
								{/* <i className="uil-bank" style={{ fontSize: '50px' }}></i> */}
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="travel-insurance" width={80} height={80}>
									<defs>
										<linearGradient id="a" x1="4.477" x2="18.358" y1="20.35" y2="6.468" gradientUnits="userSpaceOnUse">
											<stop offset="0" stopColor="#5433ff"></stop>
											<stop offset="1" stopColor="#20bdff"></stop>
										</linearGradient>
									</defs>
									<path fill="url(#a)" d="m5.05,6.25h1.2v13.5h-1.2c-1.54,0-2.8-1.23-2.8-2.75v-8c0-1.52,1.26-2.75,2.8-2.75Zm3.7,5.75c0-2.34,1.91-4.25,4.25-4.25h1.25v-1.5h-6.5v13.5h2.52c-.98-1.37-1.52-3.04-1.52-4.75v-3Zm10.25-4.25c.15,0,.3.01.45.02-.46-.9-1.41-1.52-2.5-1.52h-1.2v1.5h3.25ZM9,3.75h4c.69,0,1.25.56,1.25,1.25v1.25h1.5v-1.25c0-1.52-1.23-2.75-2.75-2.75h-4c-1.52,0-2.75,1.23-2.75,2.75v1.25h1.5v-1.25c0-.69.56-1.25,1.25-1.25Zm12.75,8.25v3c0,2.113-1.01,4.132-2.7,5.399l-.8.601c-.646.484-1.445.75-2.25.75s-1.604-.266-2.249-.749l-.801-.602c-1.69-1.268-2.7-3.286-2.7-5.399v-3c0-1.517,1.233-2.75,2.75-2.75h6c1.517,0,2.75,1.233,2.75,2.75Zm-3.126,1.584c-.23-.345-.695-.438-1.04-.208l-2.488,1.659-.565-.565c-.293-.293-.768-.293-1.061,0s-.293.768,0,1.061l1,1c.145.145.337.22.53.22.145,0,.289-.041.416-.126l3-2c.345-.229.438-.695.208-1.04Z"></path>
								</svg>

								<div className="w-100">
									<h4>{item.bankName}</h4>
									<div className="d-between mt-2 mb-1">
										<div>{t('POS')}</div>
										<div>{item.posName}</div>
									</div>
									<div className="d-between mb-1">
										<div>{t('BALANCE')}</div>
										<b>{formatMoney(item.bankBalance)} <small>{item.currencyName}</small></b>
									</div>
								</div>
							</div>
						</div>
					</Link>
				))
				}
			</div>


			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('id')}
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label>{t('TITLE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="bankName" {...register('bankName')} />
							<span className="text-danger text-sm">{errors.bankName?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
							<Controller
								name="currencyId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={currencies}
										value={currencies.find(option => option.id === getValues('currencyId')) || ''}
										onChange={(e) => setValue('currencyId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('id')}
									/>
								}
							/>
						</div>
						<div className="form-group">
							<label>{t('BALANCE')}</label>
							<input type="number" className="form-control" name="bankBalance" {...register('bankBalance')} disabled={getValues('id')} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('BANK')}<span className="required-mark">*</span></label>
							<Select
								options={banks}
								value={banks.find(option => option.bankId === selectedItem.bankId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'bankId': option.bankId })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.bankName}
								getOptionValue={(option) => option.bankId}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}<span className="required-mark">*</span></label>
							<Select
								options={operationTypes}
								value={operationTypes.find(option => option.id === selectedItem.operationType)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'operationType': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amount" autoFocus
								value={selectedItem.amount}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amount': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100" disabled={!selectedItem.amount || !selectedItem.note}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
