import React from "react";
import { Link } from "react-router-dom";

//i18n
import SidebarContent from "./SidebarContent";

import logoIcon from "../../assets/images/logo_icon.svg";
import instagram from "../../assets/images/instagram-logo.png";

const Sidebar = () => {
	return (
		<div className="vertical-menu">
			<div className="navbar-brand-box">
				<Link to="/dashboard" className="logo logo-dark" tabIndex="-1">
					<span className="logo-sm">
						<img src={logoIcon} alt="logoIcon" />
					</span>
					<span className="logo-lg">
						<img src={logoIcon} alt="logo" width={35} height={35} />
					</span>
				</Link>
			</div>
			<div data-simplebar className="h-100">
				<SidebarContent />
			</div>

			<div className="sidebar-icons">
				<Link className="sidebar-icon" to="https://t.me/mDokon" target="_blank">
					<i className="uil-telegram" style={{ color: '#229ED9' }}></i>
				</Link>
				<Link className="sidebar-icon" to="https://www.facebook.com/mdokon.uz" target="_blank">
					<i className="uil-facebook" style={{ color: '#1877F2' }}></i>
				</Link>
				<Link className="sidebar-icon" to="https://www.instagram.com/mdokon.uz" target="_blank">
					<img src={instagram} width={20} height={20} alt="instagram"></img>
				</Link>
			</div>
		</div>
	);
};

export default Sidebar