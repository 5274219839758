import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import XLSX from 'xlsx';
import { toast } from 'react-toastify'

import Select from 'react-select'
import { Dropdown, Modal } from 'react-bootstrap'
import FilterSearch from "pages/parts/FilterSearch"
import Pagination from 'pages/parts/Pagination'

import { PGET, GET, POST } from 'helpers/api'
import { findFromArrayById, formatMoney } from 'helpers/helpers'
import { SET_REPORT_SELECTED_ORGANIZATION } from "store/report/actions"
import useDidMountEffect from "pages/parts/useDidMountEffect"


function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const URL = "/services/web/api/report-organization-pageList"
	const URL_TOTAL = `/services/web/api/report-organization-total`

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)
	const reduxTableFilter = useSelector(state => state.tableFilter)

	const amountTypes = [{ 'id': 2, 'name': t('OUT_BALANCE') }, { 'id': 1, 'name': t('AMORTIZATION_ORGANIZATION') }]
	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 2, 'name': t('TERMINAL') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [modalPayment, setModalPayment] = useState(false)

	const [pageCount, setPageCount] = useState(0)

	const [totalAmount, setTotalAmont] = useState([])

	const [poses, setPoses] = useState([])
	const [paymentPoses, setPaymentPoses] = useState([])
	const [data, setData] = useState([])

	const [selectedItem, setSelectedItem] = useState({})

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
		page: 0,
		random: 0
	})

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.paymentTypeId = 1
		itemCopy.amountTypeId = 2
		itemCopy.currencyId = 1
		itemCopy.amountIn = ''
		itemCopy.amountOut = 0
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		if (selectedItemCopy.amountTypeId === 2) {
			selectedItemCopy.amountOut = selectedItemCopy.amountIn
			selectedItemCopy.amountIn = 0
		}

		var sendData = {}
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.amountOut = selectedItemCopy.amountOut
		sendData.currencyId = selectedItemCopy.currencyId
		sendData.organizationId = selectedItemCopy.organizationId
		sendData.paymentTypeId = selectedItemCopy.paymentTypeId
		sendData.posId = selectedItemCopy.posId
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/web/api/organization-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			setSelectedItem({ ...selectedItem, amountIn: '' })
			getData()
		}
	}

	function showPaymentModal(bool) {
		if (!bool) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.paymentTypeId = 1
			selectedItemCopy.amountTypeId = 1
			selectedItemCopy.currencyId = 1
			selectedItemCopy.amountIn = ''
			selectedItemCopy.amountOut = 0
		}

		if (!selectedItem?.organizationId) {
			toast.warning(t('NO_SUPPLIER_SELECTED'))
			return
		}

		setModalPayment(bool)
	}

	function redirectToInner(item) {
		if (filterData.posId) {
			item.posId = filterData.posId
		} else {
			item.posId = ''
		}
		dispatch(SET_REPORT_SELECTED_ORGANIZATION(item))
		navigate(`/report/suppliers/history`)
	}

	async function exportToExcel() {
		const response = await GET('/services/web/api/report-organization-excel')

		var excelHeaders = [[t('ORGANIZATION'), t('POS'), t('DEBT') + (' (Сум)'), t('DEBT') + (' (USD)')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].organizationName)
			excelItems.push(response[i].posName)
			for (let j = 0; j < response[i]['balanceList'].length; j++) {
				if (response[i]['balanceList'].length === 1) {
					if (response[i]['balanceList'][j]['currencyName'] === "USD") {
						excelItems.push(0)
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
					}
					if (response[i]['balanceList'][j]['currencyName'] === "Сум") {
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
						excelItems.push(0)
					}
				}
				if (response[i]['balanceList'].length === 2) {
					if (response[i]['balanceList'][j]['currencyName'] === "USD") {
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
					}
					if (response[i]['balanceList'][j]['currencyName'] === "Сум") {
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
					}
				}
			}

			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('SUPPLIERS') + ".xlsx");
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			posId: null,
			search: '',
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL, { ...filterData }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
		const temporaryTotalAmount = await GET(URL_TOTAL, { ...filterData })
		setTotalAmont(temporaryTotalAmount)
	}

	async function paginate(data) {
		const response = await PGET(URL, { ...filterData, page: data.selected, size: reduxSettings.size }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			setPaymentPoses([...response])
			response.unshift({ 'id': null, 'name': t('ALL') })
			setPoses(response)
		}
	}

	useEffect(() => {
		if (reduxFilterData?.reportSuppliers) {
			setFilterData({ ...filterData, ...reduxFilterData?.reportSuppliers, random: filterData.random + 1 })
		} else {
			getData()
		}
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'reportSuppliers', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}
									reduxFilterSearch={true}
								/>
							</div>
							<div>
								<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-outline-danger me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-outline-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
														{t('APPLY')}
													</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={exportToExcel}>
									EXCEL
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={showPaymentModal}>
									{t('PAY')}
								</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount?.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-danger text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('SUPPLIER')}</th>
									<th>{t('POS')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-end">{t('AMOUNT')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr className={'tr-middle ' + (
										(selectedItem &&
											selectedItem.posId === item.posId &&
											selectedItem.organizationId === item.organizationId) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>
											<span className="text-primary cursor table-first-column-clickable"
												onClick={() => redirectToInner(item)}>
												{item.rowNum}. {item.organizationName}
											</span>
										</td>
										<td>{item.posName}</td>
										<td className="text-center">
											{item.expired ?
												<span className="bg-danger bg-border-square"></span>
												:
												<span></span>
											}
										</td>
										<td className="text-end">
											{item?.balanceList?.map((item, index) => (
												<div className={item.totalAmount > 0 ? 'text-success' : 'text-danger'} key={index}>
													<span className="me-1">{formatMoney(item.totalAmount)}</span>
													<b>{item.currencyName}</b>
												</div>
											))}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} filters={reduxTableFilter?.filters}></Pagination>
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width"
				backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* <div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('POS')}</span>
						<span>{selectedItem.posName}</span>
					</div> */}
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('ORGANIZATION')}</span>
						<span>{selectedItem.organizationName}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}</label>
							<Select
								options={paymentPoses}
								value={paymentPoses.find(option => option.id === selectedItem.posId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'posId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={currencies}
								value={currencies.find(option => option.id === selectedItem.currencyId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								options={amountTypes}
								value={amountTypes.find(option => option.id === selectedItem.amountTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'amountTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="amountIn" autoFocus
								value={selectedItem.amountIn}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value.replace(/[^0-9.]/g, '') })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100" disabled={!selectedItem.amountIn && !selectedItem.amountOut}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
