import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'

import { PGET } from 'helpers/api'
import { formatMoney, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/wms/api/transfer-pageList/${reduxAccount.posId}`

	const [modalTableControl, setModalTableControl] = useState(false)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function search(data, searchValue, totalCount) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(totalCount)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DISPLACEMENT')}
				</h4>
				<div className="d-flex">
					<Link className="btn btn-outline-primary" to="/wms/transfer/create">

						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('SENDER')}</th>
									<th>{t('RECIPIENT')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('SENDED')}</th>
									<th className="text-center">{t('SEND_DATE')}</th>
									<th className="text-center">{t('RECEIVED')}</th>
									<th className="text-center">{t('RECEIVED_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.fromPosName}</td>
										<td>{item.toPosName}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">
											{!item.send ?
												<span className="bg-secondary bg-border text-white">{t('NO')}</span>
												:
												<span className="bg-success bg-border text-white">{t('YES')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.sendDate)}</td>
										<td className="text-center">
											{!item.received ?
												<span className="bg-secondary bg-border text-white">{t('NO')}</span>
												:
												<span className="bg-success bg-border text-white">{t('YES')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.receivedDate)}</td>
										<td className="text-center">
											{(item.status === 0 && !item.send) &&
												<span className="bg-secondary bg-border text-white">{t('DRAFT')}</span>
											}
											{(item.status === 0 && item.send) &&
												<span className="bg-secondary bg-border text-white">{t('ON_THE_WAY')}</span>
											}
											{item.status === 1 &&
												<span className="bg-warning bg-border text-white text-nowrap">{t('PARTIALLY_RECIEVED')}</span>
											}
											{item.status === 2 &&
												<span className="bg-success bg-border text-white">{t('FULLY_RECIEVED')}</span>
											}
											{item.status === 3 &&
												<span className="bg-success bg-border text-white">{t('CLOSED')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td>
											<div className="table-actions">
												{item.send ?
													<Link to={'/wms/transfer/preview/' + item.id} className="me-2">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-eye"></i>
														</div>
													</Link>
													:
													<Link to={'/wms/transfer/update/' + item.id} className="me-2">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-edit-alt"></i>
														</div>
													</Link>
												}
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableNomenclature'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
