import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux"

import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import { PGET, GET, POST, PUT } from 'helpers/api'
import { formatMoney, formatDate, checkRole } from 'helpers/helpers'
import imgCashbox from 'assets/images/cashbox.png'

function Index() {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: { name: "", posId: "" }
	});
	const { errors } = formState

	const reduxAccount = useSelector(state => state.account)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const canCreate = checkRole('POS_SETTINGS_POS_CASHBOX_ADD')

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [posId, setPosId] = useState(null)

	async function create(validatedData) {
		var sendData = { ...validatedData }

		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/cashbox', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/cashbox', sendData, { loader: true })
		}

		if (response) {
			getData(posId)
			setShowModal(false)
			reset()
		}
	}

	function openModal(item) {
		for (const [key, value] of Object.entries(item)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		reset()
		setShowModal(false)
	}

	async function getData(localPosId) {
		var filter = {
			posId: localPosId,
			size: 50,
		}
		const response = await PGET(`/services/web/api/cashbox-pageList/${localPosId}`, filter, { loader: true })
		if (response) {
			setData(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		setPosId(reduxAccount.posId)
		getData(reduxAccount.posId)
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="d-flex justify-content-between mb-2">
				<div className="d-flex">
					<div style={{ 'width': '200px' }}>
						<Select
							className="w-100"
							options={poses}
							value={poses.find(option => option.id === posId) || ''}
							onChange={(option) => {
								getData(option.id)
								setPosId(option.id)
							}}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
				</div>
				<div className="d-flex">
					{canCreate &&
						<button className="btn btn-outline-primary" onClick={() => setShowModal(true)}>
							{t('CREATE')}
						</button>
					}
				</div>
			</div>

			<div className="row">
				{data.map((item, index) => (
					<div className="col-md-3 mb-3" key={index}>
						<div className="card">
							<div className="card-body">
								<button type="button" className="table-action-button table-action-primary-button position-absolute"
									style={{ 'right': '17px' }}
									onClick={() => openModal(item)}>
									<i className="uil-edit-alt"></i>
								</button>
								<div className="d-flex justify-content-center mb-2">
									<img width="100" height="100"
										src={imgCashbox}
										alt="react" />
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('CASHBOX')}:</div>
									<div>
										{item.name}
										{(item.openShiftDate && item.closeShiftDate == null) &&
											<span>({t('OPEN')})</span>
										}
										{item.closeShiftDate &&
											<span>({t('CLOSED')})</span>
										}
									</div>
								</div>
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('POS')}:</div>
									<div>{item.posName}</div>
								</div>
								{item.openShiftDate ?
									<div className="d-flex justify-content-between">
										<div className="fw-600">{t('SHIFT_OPENING_DATE')}:</div>
										<div className="text-truncate">{formatDate(item.openShiftDate)}</div>
									</div>
									:
									<div className="d-flex justify-content-between">
										<div className="fw-600">{t('SHIFT_OPENING_DATE')}:</div>
										<div className="text-truncate">{formatDate(item.closeShiftDate)}</div>
									</div>
								}
								<div className="d-flex justify-content-between">
									<div className="fw-600">{t('CASHIER')}:</div>
									<div>{item.shiftCashier}</div>
								</div>
								<div className="d-flex justify-content-between mb-2">
									<div className="fw-600">{t('BALANCE')}:</div>
									<div className="text-truncate">
										{item.shiftBalanceList?.map((item2, index2) => (
											<div className="d-flex justify-content-end" key={index2}>
												<span className="me-2">{formatMoney(item2.balance)}</span>
												{item2.currencyName}
											</div>
										))
										}
									</div>
								</div>
								<div className="d-flex">
									<Link to={'/cashbox/shifts/' + item.id} className="btn btn-outline-primary w-100">{t('SHIFTS')}</Link>
								</div>
							</div>
						</div>
					</div>
				))
				}
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form autoComplete="off" onSubmit={handleSubmit(create)}>
						<div className="form-group">
							<label>{t('CASHBOX_NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name')} />
							<span className="text-danger text-sm">{errors.name?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId'))}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
							<Controller
								name="defaultCurrency"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={currencies}
										value={currencies.find(option => option.id === getValues('defaultCurrency'))}
										onChange={(e) => setValue('defaultCurrency', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-danger w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-outline-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
